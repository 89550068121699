import { TextInterpreter } from "../../blockly/textInterpreter";
import { validateBooleanField, validateStringField } from "../../../utils/validation";
var CompletedStage = /** @class */ (function () {
    function CompletedStage(scriptKey, leaveScript, message) {
        this.name = "Completed";
        this.scriptKey = scriptKey;
        this.leaveScript = leaveScript;
        this.message = message;
    }
    CompletedStage.prototype.toJson = function () {
        return [
            this.scriptKey,
            this.leaveScript,
            this.message
        ];
    };
    return CompletedStage;
}());
var CompletedStageInterpreter = /** @class */ (function () {
    function CompletedStageInterpreter() {
        this.blockType = "completed_stage";
    }
    CompletedStageInterpreter.prototype.interpret = function (block) {
        var message = TextInterpreter.interpret(block.getInputTargetBlock("message"));
        if (message == null)
            throw new Error("Message is null");
        return new CompletedStage(block.getFieldValue("script_key"), block.getFieldValue("leave_script"), message);
    };
    CompletedStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "script_key")
            && validateBooleanField(block, "leave_script")
            && TextInterpreter.validate(block, block.getInputTargetBlock("message"));
    };
    return CompletedStageInterpreter;
}());
export { CompletedStage, CompletedStageInterpreter };
