import { validateEnumeration, validateNumberField } from "../../../utils/validation";
var WaitStage = /** @class */ (function () {
    function WaitStage(ticks) {
        this.name = "Wait";
        this.ticks = ticks;
    }
    WaitStage.prototype.toJson = function () {
        return this.ticks;
    };
    return WaitStage;
}());
var WaitStageInterpreter = /** @class */ (function () {
    function WaitStageInterpreter() {
        this.blockType = "wait_stage";
    }
    WaitStageInterpreter.prototype.interpret = function (block) {
        var time = block.getFieldValue("time");
        var timeUnit = block.getFieldValue("time_unit");
        var timeInTicks = parseInt(time) * (timeUnit == "ticks" ? 1 : (timeUnit === "seconds" ? WaitStageInterpreter.secondToTickRatio : WaitStageInterpreter.minuteToTickRatio));
        return new WaitStage(timeInTicks);
    };
    WaitStageInterpreter.prototype.validate = function (block) {
        return validateNumberField(block, "time", 0, 36000)
            && validateEnumeration(block, "time_unit", ["ticks", "seconds", "minutes"]);
    };
    WaitStageInterpreter.secondToTickRatio = 20;
    WaitStageInterpreter.minuteToTickRatio = 1200;
    return WaitStageInterpreter;
}());
export { WaitStage, WaitStageInterpreter };
