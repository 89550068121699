import { BlockInterpretationException, NoInterpreterException } from "../utils/exception";
import { WrappedStage } from "./../types/stage";
import { MessageType, statusMessage } from "../utils/console";
var InterpreterManager = /** @class */ (function () {
    function InterpreterManager() {
        this.interpreters = [];
        this.postProcessors = [];
        this.exception = ["text", "text_join", "text_changeCase", "text_trim", "lists_create_with", "dialog_message_object", "pokemon_object", "pokemon_move_object", "material_object", "pokemon_ivs_object", "pokemon_evs_object"];
    }
    InterpreterManager.prototype.validateAll = function (blocks, onlyEnabledBlocks) {
        if (onlyEnabledBlocks === void 0) { onlyEnabledBlocks = true; }
        if (blocks.length === 0)
            return true;
        for (var _i = 0, blocks_1 = blocks; _i < blocks_1.length; _i++) {
            var block = blocks_1[_i];
            if (this.exception.includes(block.type)
                || (onlyEnabledBlocks && !block.isEnabled()))
                continue;
            if (!this.validate(block))
                return false;
            if (block.type === "controls_if") {
                var condition = block.getInputTargetBlock("IF0");
                if (condition && !this.validate(condition))
                    return false;
            }
        }
        return true;
    };
    InterpreterManager.prototype.validate = function (block) {
        var blockType = block.type;
        block.setWarningText(null);
        var interpreterValidated = this.getValidatedInterpreter(blockType);
        if (!interpreterValidated)
            return true;
        return interpreterValidated.validate(block);
    };
    InterpreterManager.prototype.interpretAll = function (blocks) {
        var stages = [];
        if (blocks.length === 0)
            return stages;
        var previous = null;
        for (var _i = 0, blocks_2 = blocks; _i < blocks_2.length; _i++) {
            var block = blocks_2[_i];
            if (this.exception.includes(block.type) || !block.isEnabled())
                continue;
            try {
                var stage = this.interpret(block);
                var wrappedStage = new WrappedStage(stage, previous, null, block.getCommentText());
                if (previous !== null)
                    previous.setNext(wrappedStage);
                stages.push(wrappedStage);
                previous = wrappedStage;
            }
            catch (exception) {
                statusMessage(MessageType.Error, exception.message);
                throw exception;
            }
        }
        return this.postProcess(stages);
    };
    InterpreterManager.prototype.postProcess = function (stages) {
        var _loop_1 = function (stage) {
            this_1.postProcessors.filter(function (postProcessor) { return postProcessor.triggerStage == stage.stage.name; })
                .forEach(function (postProcessor) { return stages = postProcessor.process(stages, stage, stages.indexOf(stage)); });
        };
        var this_1 = this;
        for (var _i = 0, stages_1 = stages; _i < stages_1.length; _i++) {
            var stage = stages_1[_i];
            _loop_1(stage);
        }
        return stages;
    };
    InterpreterManager.prototype.interpret = function (block) {
        var blockType = block.type;
        var interpreter = this.getInterpreter(blockType);
        try {
            return interpreter.interpret(block);
        }
        catch (exception) {
            if (exception instanceof NoInterpreterException)
                throw exception;
            throw new BlockInterpretationException(exception, block);
        }
    };
    InterpreterManager.prototype.registerInterpreter = function (interpreter) {
        this.interpreters.push(interpreter);
    };
    InterpreterManager.prototype.registerPostProcessor = function (postProcessor) {
        this.postProcessors.push(postProcessor);
    };
    InterpreterManager.prototype.getInterpreter = function (blockType) {
        for (var _i = 0, _a = this.interpreters; _i < _a.length; _i++) {
            var interpreter = _a[_i];
            if (interpreter.blockType == blockType) {
                return interpreter;
            }
        }
        throw new NoInterpreterException(blockType);
    };
    InterpreterManager.prototype.getValidatedInterpreter = function (blockType) {
        try {
            var interpreter = this.getInterpreter(blockType);
            if ('validate' in interpreter)
                return interpreter;
        }
        catch (e) {
        }
        return null;
    };
    InterpreterManager.prototype.getInterpreters = function () {
        return this.interpreters;
    };
    return InterpreterManager;
}());
export { InterpreterManager };
