import { Region } from "../../../types/minecraft";
import { validateNumberField, validateRegion, validateStringField } from "../../../utils/validation";
var PaintingWallStage = /** @class */ (function () {
    function PaintingWallStage(canvasColor, paintColor, resetTime, canvas, colors) {
        this.name = "PaintingWall";
        this.canvasColor = canvasColor;
        this.paintColor = paintColor;
        this.resetTime = resetTime;
        this.canvas = canvas;
        this.colors = colors;
    }
    PaintingWallStage.prototype.toJson = function () {
        return [
            this.canvas.min.x,
            this.canvas.min.y,
            this.canvas.min.z,
            this.canvas.max.x,
            this.canvas.max.y,
            this.canvas.max.z,
            this.canvasColor,
            this.colors.min.x,
            this.colors.min.y,
            this.colors.min.z,
            this.colors.max.x,
            this.colors.max.y,
            this.colors.max.z,
            this.paintColor,
            this.resetTime
        ];
    };
    return PaintingWallStage;
}());
var PaintingWallStageInterpreter = /** @class */ (function () {
    function PaintingWallStageInterpreter() {
        this.blockType = "painting_wall_stage";
    }
    PaintingWallStageInterpreter.prototype.interpret = function (block) {
        var canvas = Region.fromBlock(block);
        var colors = Region.fromBlock(block, "colors_");
        var canvasColor = block.getFieldValue("canvas_color");
        var paintColor = block.getFieldValue("paint_color");
        var resetTime = parseInt(block.getFieldValue("reset_time"));
        return new PaintingWallStage(canvasColor, paintColor, resetTime, canvas, colors);
    };
    PaintingWallStageInterpreter.prototype.validate = function (block) {
        var areRegionsValid = validateRegion(block)
            && validateRegion(block, "colors_");
        if (!areRegionsValid)
            return false;
        var canvas = Region.fromBlock(block);
        var colors = Region.fromBlock(block, "colors_");
        if (!canvas.contains(colors)) {
            block.setWarningText("The colors region must be contained in the canvas region");
            return false;
        }
        else if (canvas.size() > 500) {
            block.setWarningText("The canvas region is too big");
            return false;
        }
        else if (colors.size() > 500) {
            block.setWarningText("The colors region is too big");
            return false;
        }
        return validateStringField(block, "canvas_color")
            && validateStringField(block, "paint_color")
            && validateNumberField(block, "reset_time", 1, 720);
    };
    return PaintingWallStageInterpreter;
}());
export { PaintingWallStage, PaintingWallStageInterpreter };
