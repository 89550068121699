import { Location } from "../../../types/minecraft";
import { validateLocation } from "../../../utils/validation";
var TeleportStage = /** @class */ (function () {
    function TeleportStage(location) {
        this.name = "Teleport";
        this.location = location;
    }
    TeleportStage.prototype.toJson = function () {
        return this.location.toJson();
    };
    return TeleportStage;
}());
var TeleportStageInterpreter = /** @class */ (function () {
    function TeleportStageInterpreter() {
        this.blockType = "teleport_stage";
    }
    TeleportStageInterpreter.prototype.interpret = function (block) {
        return new TeleportStage(Location.fromBlock(block));
    };
    TeleportStageInterpreter.prototype.validate = function (block) {
        return validateLocation(block);
    };
    return TeleportStageInterpreter;
}());
export { TeleportStage, TeleportStageInterpreter };
