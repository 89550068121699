var ListInterpreter = /** @class */ (function () {
    function ListInterpreter() {
    }
    ListInterpreter.interpret = function (source) {
        if (source == null || !ListInterpreter.isList(source))
            throw new Error("List block must not be null");
        var list = [];
        source.inputList.forEach(function (value) {
            if (!value.connection)
                return;
            var input = value.connection.targetBlock();
            if (input)
                list.push(input);
        });
        return list;
    };
    ListInterpreter.interpretList = function (source, targetType) {
        if (source == null || !ListInterpreter.isList(source))
            throw new Error("List block must not be null");
        var list = [];
        source.inputList.forEach(function (value) {
            if (!value.connection)
                return;
            var input = value.connection.targetBlock();
            if (input && input.type === targetType)
                list.push(input);
        });
        return list;
    };
    ListInterpreter.isList = function (source) {
        return source.type === "lists_create_with";
    };
    return ListInterpreter;
}());
export { ListInterpreter };
