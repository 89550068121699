import {ScriptTemplate} from "../../templates/template";

class Script {

    id: string
    account_id: string
    name: string
    blocky: string
    pokefind: string

    constructor(id: string, account_id: string, name: string, blocky: string, pokefind: string) {
        this.id = id;
        this.account_id = account_id;
        this.name = name;
        this.blocky = blocky;
        this.pokefind = pokefind;
    }
}

type ScriptContextProps = {
    script: Script | null
    scripts: Script[] | null
    setScript: (script: Script | null) => void
    areScriptsLoading: boolean
    isScriptLoading: boolean
    newScript: (name: string) => Promise<Script>
    newScriptWithTemplate: (name: string, template: ScriptTemplate) => Promise<Script>
    saveScript: (blockly: string, pokefind: string) => Promise<void>
    onScriptDeleted: (script: Script) => Promise<void | number | string>
}

const initialScriptContext: ScriptContextProps = {
    script: null,
    scripts: null,
    areScriptsLoading: true,
    setScript: (script: Script | null) => {
    },
    isScriptLoading: true,
    saveScript: async (blockly: string, pokefind: string) => {
    },
    newScript: async (name: string): Promise<Script> => {
        return new Script("", "", name, "", "");
    },
    newScriptWithTemplate: async (name: string, template: ScriptTemplate): Promise<Script> => {
        return new Script("", "", name, "", "");
    },
    onScriptDeleted: async (script: Script) => {
    }
}

export {Script, initialScriptContext};
export type {ScriptContextProps};