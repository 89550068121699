import {RegistryItem} from "blockly/core/contextmenu_registry";
import Blockly from "blockly";
import {Workspace} from "react-blockly";
import {DocumentationManager} from "./DocumentationManager";
import {StageDocumentation} from "./_models";

class DocumentationContextMenu {

    item: RegistryItem
    workspace: Workspace
    documentationManager: DocumentationManager
    showStageDocumentation: (stage: StageDocumentation) => void;

    constructor(workspace: Workspace, showStageDocumentation: (stage: StageDocumentation) => void) {
        this.workspace = workspace
        this.showStageDocumentation = showStageDocumentation
        this.documentationManager = new DocumentationManager()
        this.item = {
            scopeType: Blockly.ContextMenuRegistry.ScopeType.BLOCK,
            displayText: "Documentation",
            weight: 0,
            id: "documentation",
            preconditionFn: (scope): string => {
                const block = scope.block
                if (block === null || block === undefined)
                    return 'hidden'
                return this.documentationManager.hasDocumentation(block.type) ? 'enabled' : 'hidden'
            },
            callback: p1 => {
                const block = p1.block
                if (block === null || block === undefined)
                    return
                const doc = this.documentationManager.getDocumentation(block.type)
                if (doc === undefined)
                    return
                showStageDocumentation(doc)
            }
        }
    }

    isRegistered(): boolean {
        return Blockly.ContextMenuRegistry.registry.getItem("documentation") != null
    }

    register() {
        if (!this.isRegistered())
            Blockly.ContextMenuRegistry.registry.register(this.item)
    }

}

export {DocumentationContextMenu}