import { validateBooleanField, validateEntityKey, validateEnumeration, validateStringField } from "../../../utils/validation";
var HighlightClickEntityStage = /** @class */ (function () {
    function HighlightClickEntityStage(entityKey, look, clickType) {
        this.name = "HighlightClickEntity";
        this.entityKey = entityKey;
        this.look = look;
        this.clickType = clickType;
    }
    HighlightClickEntityStage.prototype.toJson = function () {
        if (this.clickType === "any")
            return [
                this.entityKey,
                this.look
            ];
        return [
            this.entityKey,
            this.look,
            this.clickType
        ];
    };
    return HighlightClickEntityStage;
}());
var HighlightClickEntityStageInterpreter = /** @class */ (function () {
    function HighlightClickEntityStageInterpreter() {
        this.blockType = "highlight_click_entity_stage";
    }
    HighlightClickEntityStageInterpreter.prototype.interpret = function (block) {
        return new HighlightClickEntityStage(block.getFieldValue("entity_key"), block.getFieldValue("look"), block.getFieldValue("click_type"));
    };
    HighlightClickEntityStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "entity_key")
            && validateEntityKey(block, block.getFieldValue("entity_key"))
            && validateBooleanField(block, "look")
            && validateEnumeration(block, "click_type", ["any", "left", "right"]);
    };
    return HighlightClickEntityStageInterpreter;
}());
export { HighlightClickEntityStage, HighlightClickEntityStageInterpreter };
