import { TextInterpreter } from "../../blockly/textInterpreter";
import { Location } from "../../../types/minecraft";
import { validateLocation, validateStringField } from "../../../utils/validation";
var CreateEntityStage = /** @class */ (function () {
    function CreateEntityStage(key, displayName, subDisplayName, location, skinTexture, skinSignature) {
        this.name = "CreateEntity";
        this.key = key;
        this.displayName = displayName;
        this.subDisplayName = subDisplayName;
        this.location = location;
        this.skinTexture = skinTexture;
        this.skinSignature = skinSignature;
    }
    CreateEntityStage.prototype.toJson = function () {
        return {
            key: this.key,
            name: [
                this.displayName,
                this.subDisplayName,
            ],
            "location": [
                this.location.x,
                this.location.y,
                this.location.z,
                this.location.yaw,
                this.location.pitch
            ],
            skin: {
                texture: this.skinTexture,
                signature: this.skinSignature
            }
        };
    };
    return CreateEntityStage;
}());
var CreateEntityStageInterpreter = /** @class */ (function () {
    function CreateEntityStageInterpreter() {
        this.blockType = "create_entity_stage";
    }
    CreateEntityStageInterpreter.prototype.interpret = function (block) {
        return new CreateEntityStage(block.getFieldValue("key"), TextInterpreter.interpret(block.getInputTargetBlock("NAME")), TextInterpreter.interpret(block.getInputTargetBlock("SUB_NAME")), Location.fromBlock(block), block.getFieldValue("skin_texture"), block.getFieldValue("skin_signature"));
    };
    CreateEntityStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "key")
            && validateStringField(block, "skin_texture")
            && validateStringField(block, "skin_signature")
            && TextInterpreter.validate(block, block.getInputTargetBlock("NAME"))
            && TextInterpreter.validate(block, block.getInputTargetBlock("SUB_NAME"))
            && validateLocation(block);
    };
    return CreateEntityStageInterpreter;
}());
export { CreateEntityStage, CreateEntityStageInterpreter };
