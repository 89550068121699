import {BlocklyWorkspace, WorkspaceSvg} from "react-blockly";
import React, {useEffect, useState} from "react";
import Blockly from "blockly";
import {BlocklyInterpreter} from "@pokefind/script-interpreter/dist/interpreter";
import * as BlockDynamicConnection from "@blockly/block-dynamic-connection";
import {DocumentationContextMenu} from "../documentation/DocumentationContextMenu";
import {StrictConnectionChecker} from "../connections/StrictConnectionChecker";
import {shadowBlockConversionChangeListener} from "@blockly/shadow-block-converter";
import {useEditorContext} from "./EditorContextProvider";
import {useThemeContext} from "../theme/ThemeContextProvider";
import {StepType, useTour} from "@reactour/tour";
import {useSessionContext} from "../sessions/SessionContextProvider";
import {useScriptContext} from "../script/ScriptContextProvider";
import {toast} from "react-toastify";
import {useDocumentationContext} from "../documentation/DocumentationContextProvider";
import '@blockly/toolbox-search';
import {DocumentationManager} from "../documentation/DocumentationManager";

const BlocklyEditor = () => {
    const {setWorkspace, setScriptState, workspace, scriptState} = useEditorContext();
    const {isFullScreen} = useThemeContext()
    const {setSteps, setIsOpen, steps} = useTour()
    const {hasCompletedInitialTour, setHasCompletedInitialTour, isLoading, isIpLoading} = useSessionContext()
    const {script, saveScript, isScriptLoading, areScriptsLoading} = useScriptContext()
    const {setShownStage} = useDocumentationContext()

    const [content, setContent] = useState<string>(script?.blocky || "{}")

    const [json, setJson] = useState("")
    const toolboxCategories = require("./../../config/toolbox.json");

    function onJsonChange(newJson: any) {
        if (newJson === null
            || newJson === undefined
            || newJson === ""
            || newJson === "{}")
            return

        setScriptState("modified")
        setJson(newJson)
    }

    // DEBUG
    useEffect(() => {
        const escape = (e: any) => {
            if (e.key !== "Escape" || !workspace)
                return
            const interpreter = new BlocklyInterpreter();
            console.log(interpreter.interpret(workspace))
        }
        window.addEventListener("keydown", escape);
        return () => window.removeEventListener("keydown", escape)
    }, [workspace]);

    useEffect(() => {
        const zoom = (e: any) => {
            if (!workspace)
                return

            if (e.altKey || e.ctrlKey) {
                const delta = e.deltaY || e.detail || e.wheelDelta;
                if (delta > 0) {
                    workspace.zoomCenter(-1)
                } else {
                    workspace.zoomCenter(1)
                }
                e.preventDefault();
            }
        }
        window.addEventListener("wheel", zoom, {passive: false});
        return () => window.removeEventListener("wheel", zoom)
    }, [workspace]);

    function onInject(newWorkspace: WorkspaceSvg) {
        setWorkspace(newWorkspace)

        // Dynamic connections
        BlockDynamicConnection.overrideOldBlockDefinitions();

        // Documentation context menu
        const documentation = new DocumentationContextMenu(newWorkspace, setShownStage);
        documentation.register()

        // Connection checkers
        if (!Blockly.registry.hasItem(Blockly.registry.Type.CONNECTION_CHECKER, 'StrictConnectionChecker'))
            Blockly.registry.register(
                Blockly.registry.Type.CONNECTION_CHECKER,
                'StrictConnectionChecker',
                new StrictConnectionChecker(),
            );

        // Shadow blocks
        newWorkspace.addChangeListener(shadowBlockConversionChangeListener);

        // Disable orphan blocks
        newWorkspace.addChangeListener(Blockly.Events.disableOrphans);

        // Disable double start hats
        newWorkspace.addChangeListener(e => {
            if (e.type !== Blockly.Events.BLOCK_CREATE)
                return
            const startBlocks = newWorkspace.getTopBlocks();
            if (startBlocks.length <= 2)
                return;
            const event = e as Blockly.Events.BlockCreate;
            if (event == null || event.blockId == null)
                return;
            const block = newWorkspace.getBlockById(event.blockId);
            if (block?.type === "click_item_stage"
                || block?.type === "click_to_start_stage"
                || block?.type === "house_enter_stage"
                || block?.type === "in_region_stage") {
                e.run(false)
                toast.error("You can only have one start block")
            }
        })

        // First time documentation
        const d = new DocumentationManager();
        const documentationListener = (e: any) => {
            if (e.type !== Blockly.Events.BLOCK_MOVE)
                return

            const event = e as Blockly.Events.BlockMove;
            if (event == null
                || event.blockId == null
                || event.oldParentId
                || !event.reason
                || !event.reason.includes("connect")
            )
                return;

            // Get block
            const block = newWorkspace.getBlockById(event.blockId);
            if (block == null || !d.hasDocumentation(block.type))
                return;

            // Check if the stage documentation was already shown to user
            const shownStages = localStorage.getItem("shownStages") || "[]"
            if (shownStages.includes(block.type))
                return;

            // Get stage documentation
            const stageDocumentation = d.getDocumentation(block.type)
            if (stageDocumentation == null)
                return;

            // Show stage documentation
            setShownStage(stageDocumentation)

            // Save stage documentation as shown
            localStorage.setItem("shownStages", JSON.stringify([...JSON.parse(shownStages), stageDocumentation.id]))
        }
        newWorkspace.addChangeListener(documentationListener);

        // Live validation
        const validate = (e: any) => {
            if (e.type !== Blockly.Events.BLOCK_CHANGE
                && e.type !== Blockly.Events.BLOCK_CREATE
                && e.type !== Blockly.Events.BLOCK_DRAG
                && e.type !== Blockly.Events.BLOCK_MOVE
                && e.type !== Blockly.Events.BLOCK_FIELD_INTERMEDIATE_CHANGE) {
                return
            }
            const interpreter = new BlocklyInterpreter();
            interpreter.validateAll(newWorkspace, false)
        }
        newWorkspace.addChangeListener(validate);

        // Tour
        const stepType: StepType[] = [
            {
                selector: '.blocklyToolboxDiv.blocklyNonSelectable',
                content: 'This is the toolbox, you can find all the blocks here. The blocks are divided into categories for better readability.',
                position: "right",
            },
            {
                selector: `.blocklyToolboxContents > div:first-child:first-child:first-child`,
                content: 'The search bar allows you to search for a block. You can search by stage or block name.',
                position: "right"
            },
            {
                selector: `.blocklyToolboxContents > div:nth-child(2)`,
                content: 'The start blocks are the first blocks you need to use in your script. They are the entry point of your script.',
                position: "right"
            },
            {
                selector: '.blocklyToolboxContents > div:nth-child(3)',
                content: 'Conditions are used to check if something is true or false. They are used in if statements or directly in the script depending on the condition.',
                position: "right",
            },
            {
                selector: '.blocklyToolboxContents > div:nth-child(4)',
                content: 'The dummy blocks are used to make non-game related actions. For example they can be used to create a new objective or key stage.',
                position: "right",
            },
            {
                selector: '.blocklyToolboxContents > div:nth-child(5)',
                content: 'Actions are used to make game related actions. For example they can be used to send a message to the player or teleport him.',
                position: "right",
            },
            {
                selector: '.blocklyToolboxContents > div:nth-child(6)',
                content: 'The objects blocks are used to provide additional information to the script. For example they can be used to define a pokemon or a dialog message.',
                position: "right",
            },
            {
                selector: '.blocklyToolboxContents > div:nth-child(8)',
                content: 'The logic statements are used to make logical operations. For example they can be used to check if two conditions are true or false and how to react to it.',
                position: "right",
            },
            {
                selector: '.blocklyToolboxContents > div:nth-child(9)',
                content: 'Math blocks allows you to make mathematical operations. For example they can be used to calculate the level of a pokemon.',
                position: "right",
            },
            {
                selector: '.blocklyToolboxContents > div:nth-child(10)',
                content: 'Text blocks allows you to make text operations. For example they can be used to concatenate two strings, make a substring or create change the case of a string.',
                position: "right",
            },
            {
                selector: '.blocklyToolboxContents > div:nth-child(11)',
                content: 'This category allows you to create lists. For example they can be used to create a list of pokemon or a list of messages.',
                position: "right",
            },
            {
                selector: '.blocklyToolboxContents > div:nth-child(13)',
                content: 'This last category contains pre assembled blocks to kick start your script. You can use them as they are or modify them to fit your needs.',
                position: "right",
            },
            {
                selector: '.blocklyZoomReset',
                content: 'This button allows you to reset the zoom of the workspace.',
                position: "left",
            },
            {
                selector: '.blocklyZoomIn',
                content: 'This button allows you to zoom in the workspace.',
                position: "left",
            },
            {
                selector: '.blocklyZoomOut',
                content: 'This button allows you to zoom out the workspace.',
                position: "left",
            },
            {
                selector: '.blocklyTrash',
                content: 'This is the trash can, you can use it to delete blocks you don\'t need anymore. If you click on it, it will open a sidebar with all the blocks you deleted. You can restore them by dragging them back into the workspace.',
                position: "left",
            }
        ]

        if (!hasCompletedInitialTour && setSteps) {
            setSteps([...stepType, ...steps])
            setIsOpen(true)
            setHasCompletedInitialTour(true)
        }
    }

    useEffect(() => {
        let interval: string | number | NodeJS.Timeout | undefined;
        const saveAndInterpretScript = async () => {
            try {
                if (script && workspace && scriptState === 'modified') {
                    const serializedWorkspace = Blockly.serialization.workspaces.save(workspace);
                    const interpretedScript = new BlocklyInterpreter().interpret(workspace) || "{}";
                    await saveScript(JSON.stringify(serializedWorkspace), interpretedScript);
                }
            } catch (e) {
                toast.error(`Error while saving script: ${e}`);
            }
        };
        interval = setInterval(saveAndInterpretScript, 300_000);
        return () => clearInterval(interval);
    }, [script, scriptState, saveScript, workspace]);


    useEffect(() => {
        if (content === script?.blocky)
            return

        if (workspace && content && content !== "{}") {
            Blockly.serialization.workspaces.load(JSON.parse(script?.blocky || "{}"), workspace);
            setContent(script?.blocky || "{}")
        } else {
            setContent(script?.blocky || "{}")
        }
    }, [script, workspace, content]);

    useEffect(() => {
        if (workspace && workspace.getTopBlocks().length > 0) {
            workspace.scrollCenter()
            workspace.zoomToFit()
        }
    }, [workspace, script]);

    if (isScriptLoading
        || isLoading
        || isIpLoading
        || areScriptsLoading
        || !(typeof script?.blocky === "string")
    )
        return (
            <div className={"fill-height"}>
                <img src={"./assets/media/workspace.jpg"} alt={"Placeholder image for workspace"}/>
            </div>
        )

    return (
        <BlocklyWorkspace
            toolboxConfiguration={toolboxCategories}
            className={isFullScreen ? "full-height" : "fill-height"}
            initialJson={JSON.parse(content)}
            workspaceConfiguration={{
                maxBlocks: 300,
                disable: isScriptLoading,
                comments: true,
                trashcan: true,
                toolboxPosition: 'start',
                css: true,
                scrollbars: false,
                sounds: true,
                oneBasedIndex: true,
                grid: {
                    spacing: 20,
                    length: 1,
                    colour: "#888",
                    snap: true,
                },
                rendererOverrides: {
                    startHats: true
                },
                zoom: {
                    controls: true,
                    pinch: true,
                },
                move: {
                    wheel: true,
                    drag: true,
                    scrollbars: true,
                },
                renderer: "thrasos"
            }}
            onJsonChange={onJsonChange}
            onInject={onInject}
            onWorkspaceChange={setWorkspace}
        />
    )
}

export {BlocklyEditor}