import { validateStringField } from "../../utils/validation";
var TextInterpreter = /** @class */ (function () {
    function TextInterpreter() {
    }
    TextInterpreter.interpret = function (textBlock) {
        if (textBlock == null)
            throw new Error("Text block must not be null");
        var text = "";
        switch (textBlock.type) {
            // Simple text
            case "text":
                text = textBlock.getFieldValue("TEXT");
                break;
            case "text_join":
                textBlock.inputList.forEach(function (input) {
                    if (!input.connection)
                        return;
                    var inputText = TextInterpreter.interpret(input.connection.targetBlock());
                    if (inputText)
                        text += inputText;
                });
                break;
            case "text_changeCase":
                var caseOption_1 = textBlock.getFieldValue("CASE");
                textBlock.inputList.forEach(function (input) {
                    if (!input.connection)
                        return;
                    var inputText = TextInterpreter.interpret(input.connection.targetBlock());
                    if (!inputText)
                        return;
                    switch (caseOption_1) {
                        case "UPPERCASE":
                            text += inputText.toUpperCase();
                            break;
                        case "LOWERCASE":
                            text += inputText.toLowerCase();
                            break;
                        case "TITLECASE":
                            text += inputText[0].toUpperCase() + inputText.slice(1).toLowerCase();
                            break;
                        default:
                            throw new Error("Unknown case option: ".concat(caseOption_1));
                    }
                });
                break;
            case "text_trim":
                var mode_1 = textBlock.getFieldValue("MODE");
                textBlock.inputList.forEach(function (input) {
                    if (!input.connection)
                        return;
                    var inputText = TextInterpreter.interpret(input.connection.targetBlock());
                    if (!inputText)
                        return;
                    switch (mode_1) {
                        case "BOTH":
                            text = inputText.trim();
                            break;
                        case "LEFT":
                            text = inputText.trimStart();
                            break;
                        case "RIGHT":
                            text = inputText.trimEnd();
                            break;
                        default:
                            throw new Error("Unknown trim mode: ".concat(mode_1));
                    }
                });
                break;
            default:
                throw new Error("Unknown text block type: ".concat(textBlock.type));
        }
        if (text === "")
            throw new Error("Text block must not be empty");
        return text;
    };
    TextInterpreter.validate = function (block, textBlock) {
        if (textBlock == null) {
            block.setWarningText("Please enter a value.");
            return false;
        }
        if (textBlock.type === "text") {
            if (validateStringField(textBlock, "TEXT")) {
                textBlock.setWarningText(null);
                return true;
            }
            return true;
        }
        return textBlock.inputList.some(function (input) {
            var _a;
            var inputBlock = (_a = input.connection) === null || _a === void 0 ? void 0 : _a.targetBlock();
            if (!inputBlock) {
                textBlock.setWarningText("Please enter a value.");
                return false;
            }
            if (textBlock.type === "text_join"
                || textBlock.type === "text_changeCase"
                || textBlock.type === "text_trim") {
                if (TextInterpreter.validate(textBlock, inputBlock)) {
                    textBlock.setWarningText(null);
                    return true;
                }
                return true;
            }
            textBlock.setWarningText("Unknown text block type: ".concat(textBlock.type));
            return false;
        });
    };
    TextInterpreter.interpretList = function (listBlock) {
        if (listBlock == null)
            throw new Error("List block must not be null");
        var textList = [];
        listBlock.inputList.forEach(function (value) {
            if (!value.connection)
                return;
            var inputText = TextInterpreter.interpret(value.connection.targetBlock());
            if (inputText)
                textList.push(inputText);
        });
        return textList;
    };
    TextInterpreter.validateList = function (block, listBlock) {
        if (listBlock == null) {
            block.setWarningText("Please enter a value.");
            return false;
        }
        if (listBlock.inputList.length === 0) {
            listBlock.setWarningText("This stage requires at least one message.");
            return false;
        }
        return listBlock.inputList.some(function (input) {
            var _a;
            var inputBlock = (_a = input.connection) === null || _a === void 0 ? void 0 : _a.targetBlock();
            if (!inputBlock) {
                listBlock.setWarningText("Please enter a value.");
                return false;
            }
            if (TextInterpreter.validate(listBlock, inputBlock)) {
                listBlock.setWarningText(null);
                return true;
            }
            return true;
        });
    };
    return TextInterpreter;
}());
export { TextInterpreter };
