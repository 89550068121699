import { validateBooleanField, validateEntityKey, validateStringField } from "../../../utils/validation";
var RideStage = /** @class */ (function () {
    function RideStage(entity, move) {
        this.name = "Ride";
        this.entity = entity;
        this.move = move;
    }
    RideStage.prototype.toJson = function () {
        if (this.move)
            return this.entity;
        return [
            this.entity,
            this.move
        ];
    };
    return RideStage;
}());
var RideStageInterpreter = /** @class */ (function () {
    function RideStageInterpreter() {
        this.blockType = "ride_stage";
    }
    RideStageInterpreter.prototype.interpret = function (block) {
        return new RideStage(block.getFieldValue("entity"), block.getFieldValue("move"));
    };
    RideStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "entity")
            && validateEntityKey(block, block.getFieldValue("entity"))
            && validateBooleanField(block, "move");
    };
    return RideStageInterpreter;
}());
export { RideStageInterpreter };
