const NoSession = () => {
    return (
        <main className="flex items-center justify-center h-screen bg-white">
            <div className="px-4 py-16 mx-auto text-center lg:px-8 lg:py-48 max-w-7xl sm:px-6 sm:py-24">
                <div className="justify-center w-full text-center lg:p-10 max-auto">
                    <div className="justify-center w-full mx-auto">

                        <p className="text-5xl tracking-tight text-black lg:text-9xl">
                            No session found
                        </p>
                        <p className="max-w-xl mx-auto mt-4 text-lg tracking-tight text-gray-400">
                            Please execute the following command in your game to create a new session:
                        </p>
                    </div>
                    <div className="flex justify-center gap-3 mt-10">
                        <code
                            className="text-sm sm:text-base inline-flex text-left items-center space-x-4 bg-gray-900 text-white rounded-lg p-2 pl-4">
                            <span className="flex gap-4">
                                <span className="shrink-0 text-gray-500">&gt;</span>
                                <span>/editor</span>
                            </span>
                            <svg className="shrink-0 h-5 w-5 transition text-gray-500 group-hover:text-white"
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z"></path>
                                <path
                                    d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z">
                                </path>
                            </svg>
                        </code>
                    </div>
                </div>
            </div>
        </main>
    )
}

export {NoSession}