var HouseEnterStage = /** @class */ (function () {
    function HouseEnterStage() {
        this.name = "HouseEnter";
    }
    HouseEnterStage.prototype.toJson = function () {
        return [];
    };
    return HouseEnterStage;
}());
var HouseEnterStageInterpreter = /** @class */ (function () {
    function HouseEnterStageInterpreter() {
        this.blockType = "house_enter_stage";
    }
    HouseEnterStageInterpreter.prototype.interpret = function () {
        return new HouseEnterStage();
    };
    return HouseEnterStageInterpreter;
}());
export { HouseEnterStage, HouseEnterStageInterpreter };
