var Location = /** @class */ (function () {
    function Location(x, y, z, yaw, pitch) {
        this._x = x;
        this._y = y;
        this._z = z;
        this._yaw = yaw;
        this._pitch = pitch;
    }
    Object.defineProperty(Location.prototype, "x", {
        get: function () {
            return this._x;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Location.prototype, "y", {
        get: function () {
            return this._y;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Location.prototype, "z", {
        get: function () {
            return this._z;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Location.prototype, "yaw", {
        get: function () {
            return this._yaw;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Location.prototype, "pitch", {
        get: function () {
            return this._pitch;
        },
        enumerable: false,
        configurable: true
    });
    Location.prototype.toJson = function () {
        if (this._yaw === 0 && this._pitch === 0)
            return [
                this._x,
                this._y,
                this._z
            ];
        else if (this._pitch === 0)
            return [
                this._x,
                this._y,
                this._z,
                this._yaw
            ];
        return [
            this._x,
            this._y,
            this._z,
            this._yaw,
            this._pitch
        ];
    };
    Location.fromBlock = function (block) {
        var x = Number(block.getFieldValue('x'));
        var y = Number(block.getFieldValue('y'));
        var z = Number(block.getFieldValue('z'));
        var yaw = Number(block.getFieldValue('yaw'));
        var pitch = Number(block.getFieldValue('pitch'));
        if (isNaN(x) || isNaN(y) || isNaN(z) || isNaN(yaw))
            return null;
        if (x < 0 || y < 0 || z < 0 || x > 512 || y > 512 || z > 512)
            return null;
        return new Location(x, y, z, yaw, pitch);
    };
    return Location;
}());
var Vector = /** @class */ (function () {
    function Vector(x, y, z) {
        this._x = x;
        this._y = y;
        this._z = z;
    }
    Object.defineProperty(Vector.prototype, "x", {
        get: function () {
            return this._x;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Vector.prototype, "y", {
        get: function () {
            return this._y;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Vector.prototype, "z", {
        get: function () {
            return this._z;
        },
        enumerable: false,
        configurable: true
    });
    Vector.prototype.toJson = function () {
        return [
            this._x,
            this._y,
            this._z
        ];
    };
    Vector.prototype.equals = function (other) {
        if (!other)
            return false;
        return this._x === other._x
            && this._y === other._y
            && this._z === other._z;
    };
    Vector.fromBlock = function (block, prefix) {
        if (prefix === void 0) { prefix = ''; }
        var x = Number(block.getFieldValue("".concat(prefix, "x")));
        var y = Number(block.getFieldValue("".concat(prefix, "y")));
        var z = Number(block.getFieldValue("".concat(prefix, "z")));
        if (isNaN(x) || isNaN(y) || isNaN(z))
            return null;
        if (x < 0 || y < 0 || z < 0 || x > 512 || y > 512 || z > 512)
            return null;
        return new Vector(x, y, z);
    };
    return Vector;
}());
var Region = /** @class */ (function () {
    function Region(min, max) {
        this._min = min;
        this._max = max;
    }
    Object.defineProperty(Region.prototype, "min", {
        get: function () {
            return this._min;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Region.prototype, "max", {
        get: function () {
            return this._max;
        },
        enumerable: false,
        configurable: true
    });
    Region.prototype.toJson = function () {
        return [
            this._min.x,
            this._min.y,
            this._min.z,
            this._max.x,
            this._max.y,
            this._max.z
        ];
    };
    Region.prototype.size = function () {
        return (this._max.x - this._min.x + 1)
            * (this._max.y - this._min.y + 1)
            * (this._max.z - this._min.z + 1);
    };
    Region.prototype.contains = function (other) {
        if (!other)
            return false;
        return this._min.x <= other._min.x
            && this._min.y <= other._min.y
            && this._min.z <= other._min.z
            && this._max.x >= other._max.x
            && this._max.y >= other._max.y
            && this._max.z >= other._max.z;
    };
    Region.fromBlock = function (block, prefix) {
        if (prefix === void 0) { prefix = ''; }
        var min = Vector.fromBlock(block, prefix + 'min_');
        var max = Vector.fromBlock(block, prefix + 'max_');
        if (min === null || max === null)
            return null;
        return new Region(min, max);
    };
    return Region;
}());
export { Location, Vector, Region };
