import { validateNumberField } from "../../../utils/validation";
var ChanceStage = /** @class */ (function () {
    function ChanceStage(chance) {
        this.name = "Chance";
        this.chance = chance / 100;
    }
    ChanceStage.prototype.toJson = function () {
        return this.chance;
    };
    return ChanceStage;
}());
var ChanceStageInterpreter = /** @class */ (function () {
    function ChanceStageInterpreter() {
        this.blockType = "chance_stage";
    }
    ChanceStageInterpreter.prototype.interpret = function (block) {
        return new ChanceStage(block.getFieldValue("chance"));
    };
    ChanceStageInterpreter.prototype.validate = function (block) {
        return validateNumberField(block, "chance", 1, 100);
    };
    return ChanceStageInterpreter;
}());
export { ChanceStage, ChanceStageInterpreter };
