import { validateEntityKey, validateStringField } from "../../../utils/validation";
var AnimateEntityStage = /** @class */ (function () {
    function AnimateEntityStage(entity, animation) {
        this.name = "AnimateEntity";
        this.entity = entity;
        this.animation = animation;
    }
    AnimateEntityStage.prototype.toJson = function () {
        return [
            this.entity,
            this.animation
        ];
    };
    return AnimateEntityStage;
}());
var AnimateEntityStageInterpreter = /** @class */ (function () {
    function AnimateEntityStageInterpreter() {
        this.blockType = "animate_entity_stage";
    }
    AnimateEntityStageInterpreter.prototype.interpret = function (block) {
        return new AnimateEntityStage(block.getFieldValue("entity"), block.getFieldValue("animation"));
    };
    AnimateEntityStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "entity")
            && validateEntityKey(block, block.getFieldValue("entity"))
            && validateStringField(block, "animation");
    };
    return AnimateEntityStageInterpreter;
}());
export { AnimateEntityStageInterpreter };
