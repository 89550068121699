import { Pokemon } from "../../../types/pokefind";
import { validateBlockInputPresence, validateBooleanField, validateLocation, } from "../../../utils/validation";
import { Location } from "../../../types/minecraft";
import { ListInterpreter } from "../../blockly/listInterpreter";
var BattleWildPokemonStage = /** @class */ (function () {
    function BattleWildPokemonStage(location, hardcore, progressLoss, conditional, pokemons) {
        this.name = "BattleWildPokemon";
        this.location = location;
        this.hardcore = hardcore;
        this.progressLoss = progressLoss;
        this.conditional = conditional;
        this.pokemons = pokemons;
    }
    BattleWildPokemonStage.prototype.toJson = function () {
        return [
            this.location.x,
            this.location.y,
            this.location.z,
            this.location.yaw,
            this.pokemons.map(function (pokemon) { return pokemon.toJson(); }),
            {
                "automatically_start": true,
                "hardcore": this.hardcore,
                "progress_loss": this.progressLoss,
                "conditional": this.conditional,
                "exp_multiplier": 0
            }
        ];
    };
    return BattleWildPokemonStage;
}());
var BattleWildPokemonStageInterpreter = /** @class */ (function () {
    function BattleWildPokemonStageInterpreter() {
        this.blockType = "battle_wild_pokemon_stage";
    }
    BattleWildPokemonStageInterpreter.prototype.interpret = function (block) {
        var pokemonBlock = block.getInputTargetBlock("pokemons");
        var pokemons;
        if (ListInterpreter.isList(pokemonBlock)) {
            pokemons = ListInterpreter.interpretList(pokemonBlock, "pokemon_object")
                .filter(function (pokemonBlock) { return pokemonBlock != null; })
                .filter(function (pokemonBlock) { return Pokemon.validate(pokemonBlock); })
                .map(function (pokemonBlock) { return Pokemon.fromBlock(pokemonBlock); });
            if (pokemons.length === 0)
                throw new Error("Pokemon list must not be empty");
        }
        else {
            pokemons = [Pokemon.fromBlock(pokemonBlock)];
        }
        return new BattleWildPokemonStage(Location.fromBlock(block), block.getFieldValue("hardcore"), block.getFieldValue("progress_loss"), block.getFieldValue("conditional"), pokemons);
    };
    BattleWildPokemonStageInterpreter.prototype.validate = function (block) {
        if (!validateLocation(block)
            || !validateBlockInputPresence(block, "pokemons")
            || !validateBooleanField(block, "progress_loss")
            || !validateBooleanField(block, "conditional")
            || !validateBooleanField(block, "hardcore"))
            return false;
        var pokemonsBlock = block.getInputTargetBlock("pokemons");
        if (ListInterpreter.isList(pokemonsBlock)) {
            return ListInterpreter.interpretList(pokemonsBlock, "pokemon_object")
                .every(function (pokemonBlock) { return Pokemon.validate(pokemonBlock); });
        }
        else {
            return Pokemon.validate(pokemonsBlock);
        }
    };
    return BattleWildPokemonStageInterpreter;
}());
export { BattleWildPokemonStage, BattleWildPokemonStageInterpreter };
