import { Region } from "../../../types/minecraft";
import { validateRegion } from "../../../utils/validation";
var InRegionStage = /** @class */ (function () {
    function InRegionStage(region) {
        this.name = "InRegion";
        this.region = region;
    }
    InRegionStage.prototype.toJson = function () {
        return this.region.toJson();
    };
    return InRegionStage;
}());
var InRegionStageInterpreter = /** @class */ (function () {
    function InRegionStageInterpreter() {
        this.blockType = "in_region_stage";
    }
    InRegionStageInterpreter.prototype.interpret = function (block) {
        return new InRegionStage(Region.fromBlock(block));
    };
    InRegionStageInterpreter.prototype.validate = function (block) {
        return validateRegion(block);
    };
    return InRegionStageInterpreter;
}());
export { InRegionStage, InRegionStageInterpreter };
