import { Region } from "../../../types/minecraft";
import { validateRegion } from "../../../utils/validation";
var ClickBlocksStage = /** @class */ (function () {
    function ClickBlocksStage(region) {
        this.name = "ClickBlocks";
        this.region = region;
    }
    ClickBlocksStage.prototype.toJson = function () {
        return this.region.toJson();
    };
    return ClickBlocksStage;
}());
var ClickBlocksStageInterpreter = /** @class */ (function () {
    function ClickBlocksStageInterpreter() {
        this.blockType = "click_blocks_stage";
    }
    ClickBlocksStageInterpreter.prototype.interpret = function (block) {
        return new ClickBlocksStage(Region.fromBlock(block));
    };
    ClickBlocksStageInterpreter.prototype.validate = function (block) {
        return validateRegion(block);
    };
    return ClickBlocksStageInterpreter;
}());
export { ClickBlocksStage, ClickBlocksStageInterpreter };
