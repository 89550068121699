import {StageDocumentation} from "./_models";

class DocumentationManager {

    docs: StageDocumentation[] = [];

    constructor() {
        this.addDocumentationList(require('./../../documentation/triggers.json'))
        this.addDocumentationList(require('./../../documentation/conditions.json'))
        this.addDocumentationList(require('./../../documentation/dummies.json'))
        this.addDocumentationList(require('./../../documentation/actions.json'))
    }

    addDocumentation(doc: StageDocumentation) {
        this.docs.push(doc)
    }

    addDocumentationList(docs: StageDocumentation[]) {
        this.docs = this.docs.concat(docs);
    }

    getDocumentation(id: string) {
        return this.docs.find(doc => doc.id === id);
    }

    getDocs() {
        return this.docs;
    }

    hasDocumentation(id: string) {
        return this.docs.some(doc => doc.id === id);
    }
}

export {DocumentationManager}