var WrappedStage = /** @class */ (function () {
    function WrappedStage(stage, previous, next, comment) {
        if (comment === void 0) { comment = null; }
        this.comment = null;
        this.id = WrappedStage.idCounter++;
        this.stage = stage;
        this.previous = previous;
        this.next = next;
        this.comment = comment;
    }
    WrappedStage.prototype.name = function () {
        return this.stage.name;
    };
    WrappedStage.prototype.hasPrevious = function () {
        return this.previous !== null;
    };
    WrappedStage.prototype.hasNext = function () {
        return this.next !== null;
    };
    WrappedStage.prototype.setPrevious = function (previous) {
        this.previous = previous;
    };
    WrappedStage.prototype.setNext = function (next) {
        this.next = next;
    };
    WrappedStage.prototype.previousStage = function () {
        return this.previous;
    };
    WrappedStage.prototype.nextStage = function () {
        return this.next;
    };
    WrappedStage.prototype.toJson = function () {
        return this.stage.toJson();
    };
    WrappedStage.prototype.getComment = function () {
        return this.comment;
    };
    WrappedStage.prototype.setComment = function (comment) {
        this.comment = comment;
    };
    WrappedStage.idCounter = 0;
    return WrappedStage;
}());
export { WrappedStage };
// Path: src/types/stage.ts
