import Blockly, {Connection, RenderedConnection} from "blockly";

class StrictConnectionChecker extends Blockly.ConnectionChecker {

    /**
     * Type check arrays must either intersect or both be null.
     * @override
     */
    doTypeChecks(a: Connection, b: Connection) {
        const checkArrayOne = a.getCheck();
        const checkArrayTwo = b.getCheck();
        console.log(checkArrayOne, checkArrayTwo)
        if (!checkArrayOne || !checkArrayTwo) {
            return checkArrayOne === checkArrayTwo;
        }

        for (let i = 0; i < checkArrayOne.length; i++) {
            if (checkArrayTwo.indexOf(checkArrayOne[i]) !== -1) {
                return true;
            }
        }
        return false;
    }


    doDragChecks(a: RenderedConnection, b: RenderedConnection, distance: number): boolean {
        console.log(a, b, distance)
        return super.doDragChecks(a, b, distance);
    }


    canConnect(a: Connection | null, b: Connection | null, isDragging: boolean, opt_distance?: number): boolean {
        console.log("canConnect", a, b, isDragging, opt_distance)
        return super.canConnect(a, b, isDragging, opt_distance);
    }

    canConnectWithReason(a: Connection | null, b: Connection | null, isDragging: boolean, opt_distance?: number): number {
        console.log("canConnectWithReason", a, b, isDragging, opt_distance)
        return super.canConnectWithReason(a, b, isDragging, opt_distance);
    }

    protected canConnectToPrevious_(a: Connection, b: Connection): boolean {
        console.log("canConnectToPrevious_", a, b)
        return super.canConnectToPrevious_(a, b);
    }
}

export {StrictConnectionChecker}