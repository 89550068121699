import { Location, Region } from "../../../types/minecraft";
import { validateLocation, validateRegion } from "../../../utils/validation";
var RespawnRegionStage = /** @class */ (function () {
    function RespawnRegionStage(location, region) {
        this.name = "RespawnRegion";
        this.location = location;
        this.region = region;
    }
    RespawnRegionStage.prototype.toJson = function () {
        return [
            this.region.min.x,
            this.region.min.y,
            this.region.min.z,
            this.region.max.x,
            this.region.max.y,
            this.region.max.z,
            this.location.x,
            this.location.y,
            this.location.z,
            this.location.yaw,
        ];
    };
    return RespawnRegionStage;
}());
var RespawnRegionStageInterpreter = /** @class */ (function () {
    function RespawnRegionStageInterpreter() {
        this.blockType = "respawn_region_stage";
    }
    RespawnRegionStageInterpreter.prototype.interpret = function (block) {
        return new RespawnRegionStage(Location.fromBlock(block), Region.fromBlock(block));
    };
    RespawnRegionStageInterpreter.prototype.validate = function (block) {
        return validateLocation(block)
            && validateRegion(block);
    };
    return RespawnRegionStageInterpreter;
}());
export { RespawnRegionStageInterpreter };
