import { validateBlockInputPresence, validateNumberField, validateStringField } from "../../../utils/validation";
var SkipStage = /** @class */ (function () {
    function SkipStage(value) {
        this.name = "Skip";
        this.value = value;
    }
    SkipStage.prototype.toJson = function () {
        return [this.value];
    };
    return SkipStage;
}());
var SkipStageInterpreter = /** @class */ (function () {
    function SkipStageInterpreter() {
        this.blockType = "skip_stage";
    }
    SkipStageInterpreter.prototype.interpret = function (block) {
        var value = block.getInputTargetBlock("NAME");
        if (!value)
            throw new Error("Skip block has no value");
        if (value.type === "math_number") {
            return new SkipStage(value.getFieldValue("NUM"));
        }
        else if (value.type === "text") {
            return new SkipStage(value.getFieldValue("TEXT"));
        }
        throw new Error("Unknown skip value type: " + value.type);
    };
    SkipStageInterpreter.prototype.validate = function (block) {
        if (!validateBlockInputPresence(block, "NAME", "Please attach a value to this block. (Can be a number or text)"))
            return false;
        var value = block.getInputTargetBlock("NAME");
        if ((value === null || value === void 0 ? void 0 : value.type) === "math_number" || (value === null || value === void 0 ? void 0 : value.type) === "text")
            return (value === null || value === void 0 ? void 0 : value.type) === "math_number"
                ? validateNumberField(value, "NUM", 1, 100)
                : validateStringField(value, "TEXT");
        block.setWarningText("This block only accepts numbers or text.");
        return false;
    };
    return SkipStageInterpreter;
}());
export { SkipStage, SkipStageInterpreter };
