import { TextInterpreter } from "../../blockly/textInterpreter";
var CommandStage = /** @class */ (function () {
    function CommandStage(value) {
        this.name = "Command";
        this.value = value;
    }
    CommandStage.prototype.toJson = function () {
        return [this.value];
    };
    return CommandStage;
}());
var CommandStageInterpreter = /** @class */ (function () {
    function CommandStageInterpreter() {
        this.blockType = "command_stage";
    }
    CommandStageInterpreter.prototype.interpret = function (block) {
        var command = TextInterpreter.interpret(block.getInputTargetBlock("NAME"));
        if (!command)
            throw new Error("Command is empty");
        return new CommandStage(command);
    };
    CommandStageInterpreter.prototype.validate = function (block) {
        return TextInterpreter.validate(block, block.getInputTargetBlock("NAME"));
    };
    return CommandStageInterpreter;
}());
export { CommandStage, CommandStageInterpreter };
