import { Region } from "../../../types/minecraft";
import { validateNumberField, validateRegion } from "../../../utils/validation";
var BeamStage = /** @class */ (function () {
    function BeamStage(region, duration) {
        this.name = "Beam";
        this.region = region;
        this.duration = duration;
    }
    BeamStage.prototype.toJson = function () {
        if (this.duration == -1)
            return [
                this.region.min.x,
                this.region.min.y,
                this.region.min.z,
                this.region.max.x,
                this.region.max.y,
                this.region.max.z
            ];
        return [
            this.region.min.x,
            this.region.min.y,
            this.region.min.z,
            this.region.max.x,
            this.region.max.y,
            this.region.max.z,
            this.duration
        ];
    };
    return BeamStage;
}());
var BeamStageInterpreter = /** @class */ (function () {
    function BeamStageInterpreter() {
        this.blockType = "beam_stage";
    }
    BeamStageInterpreter.prototype.interpret = function (block) {
        var duration = block.getFieldValue("infinite") ? -1 : block.getFieldValue("duration");
        return new BeamStage(Region.fromBlock(block), duration);
    };
    BeamStageInterpreter.prototype.validate = function (block) {
        return validateRegion(block)
            && (block.getFieldValue("infinite") || validateNumberField(block, "duration", 1, 1000000));
    };
    return BeamStageInterpreter;
}());
export { BeamStageInterpreter, BeamStage };
