import { validateNumberField } from "../../../utils/validation";
var DistanceTravelledStage = /** @class */ (function () {
    function DistanceTravelledStage(distance) {
        this.name = "DistanceTravelled";
        this.distance = distance;
    }
    DistanceTravelledStage.prototype.toJson = function () {
        return this.distance;
    };
    return DistanceTravelledStage;
}());
var DistanceTravelledStageInterpreter = /** @class */ (function () {
    function DistanceTravelledStageInterpreter() {
        this.blockType = "distance_travelled_stage";
    }
    DistanceTravelledStageInterpreter.prototype.interpret = function (block) {
        return new DistanceTravelledStage(Number(block.getFieldValue("distance")));
    };
    DistanceTravelledStageInterpreter.prototype.validate = function (block) {
        return validateNumberField(block, "distance", 0, 10000);
    };
    return DistanceTravelledStageInterpreter;
}());
export { DistanceTravelledStage, DistanceTravelledStageInterpreter };
