import React, {useEffect} from "react";
import "react-toastify/dist/ReactToastify.css";
import Blockly from "blockly"
import {HeaderComponent} from "./app/components/HeaderComponent";
import {FooterComponent} from "./app/components/FooterComponent";
import {SessionContextProvider} from "./app/sessions/SessionContextProvider";
import {BlocklyEditor} from "./app/editor/BlocklyEditor";
import {EditorContextProvider} from "./app/editor/EditorContextProvider";
import {ToastContainer} from "react-toastify";
import {useThemeContext} from "./app/theme/ThemeContextProvider";
import {StepType, TourProvider} from "@reactour/tour";
import {ScriptContextProvider} from "./app/script/ScriptContextProvider";
import {DocumentationModal} from "./app/components/DocumentationModal";
import {DocumentationContextProvider} from "./app/documentation/DocumentationContextProvider";
import LoadingScreenComponent from "./app/components/LoadingScreenComponent";
import metadata from "./metadata.json"

const App = () => {
    const {isFullScreen} = useThemeContext()

    // Load all blocks
    useEffect(() => {
        console.log("┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓")
        console.log("┃                                                                                                      ┃")
        console.log("┃     ███████  ██████ ██████  ██ ██████  ████████     ███████ ██████  ██ ████████  ██████  ██████      ┃")
        console.log("┃     ██      ██      ██   ██ ██ ██   ██    ██        ██      ██   ██ ██    ██    ██    ██ ██   ██     ┃")
        console.log("┃     ███████ ██      ██████  ██ ██████     ██        █████   ██   ██ ██    ██    ██    ██ ██████      ┃")
        console.log("┃          ██ ██      ██   ██ ██ ██         ██        ██      ██   ██ ██    ██    ██    ██ ██   ██     ┃")
        console.log("┃     ███████  ██████ ██   ██ ██ ██         ██        ███████ ██████  ██    ██     ██████  ██   ██     ┃")
        console.log("┃                                                                                                      ┃")
        console.log("┃                                                                                                      ┃")
        console.log("┃     ┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓                                                        ┃")
        console.log("┃     ┃ PokéFind Script Editor                ┃                                                        ┃")
        console.log("┃     ┃ Made by Roch Blondiaux                ┃                                                        ┃")
        console.log(`┃     ┃ Version: ${metadata.version}                          ┃                                                        ┃`)
        console.log(`┃     ┃ Build: ${metadata.build}                             ┃                                                        ┃`)
        console.log("┃     ┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛                                                        ┃")
        console.log("┃                                                                                                      ┃")
        console.log("┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛")

        Blockly.defineBlocksWithJsonArray([require('./blocks/trigger/houseEnterStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/trigger/clickToStartStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/trigger/inRegionStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/trigger/clickItemStage.json')]);

        Blockly.defineBlocksWithJsonArray([require('./blocks/dummy/commentStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/dummy/dummyStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/dummy/authorStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/dummy/skipStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/dummy/leaveStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/dummy/nameStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/dummy/allowRejoiningStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/dummy/descriptionStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/dummy/noFlyRegionStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/dummy/noPokemonSummonRegionStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/dummy/noPokemonSummonStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/dummy/objectiveStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/dummy/keyStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/dummy/paintingWallStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/dummy/pvpRegionStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/dummy/respawnStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/dummy/respawnRegionStage.json')]);

        Blockly.defineBlocksWithJsonArray([require('./blocks/action/createEntityStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/sendMessageStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/openMenuStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/teleportStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/playSoundStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/commandStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/completeStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/checkpointStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/waitStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/sendCompleteMessageStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/potionStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/hologramStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/globalHologramStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/dialogStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/chairStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/startTimerStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/stopTimerStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/moveEntityStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/battleNpcStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/battleWildPokemonStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/createPokemonEntityStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/createMinecraftEntityStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/showStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/hideStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/teleportEntityStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/trackStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/controlledTrackStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/animateEntityStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/rideStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/unrideStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/action/beamStage.json')]);

        Blockly.defineBlocksWithJsonArray([require('./blocks/conditions/chanceStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/conditions/playTimeStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/conditions/clickEntityStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/conditions/clickBlockStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/conditions/completedStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/conditions/distanceTravelledStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/conditions/notCompletedStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/conditions/clickBlocksStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/conditions/highlightClickEntityStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/conditions/regionEntryStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/conditions/regionExitStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/conditions/bookStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/conditions/hasItemStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/conditions/cumulativePickupItemStage.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/conditions/keyPressedStage.json')]);

        Blockly.defineBlocksWithJsonArray([require('./blocks/objects/dialogMessageObject.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/objects/materialObject.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/objects/pokemonObject.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/objects/pokemonMoveObject.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/objects/pokemonIVSObject.json')]);
        Blockly.defineBlocksWithJsonArray([require('./blocks/objects/pokemonEVSObject.json')]);
    }, []);


    const steps: StepType[] = [
        {
            selector: "#save-button",
            content: 'This button allows you to save your code to the server.',
        },
        {
            selector: "#script-list-button",
            content: 'This dropdown allows you to select a script to edit.',
        },
        {
            selector: "#delete-button",
            content: 'This button allows you to delete the current script.',
        },
        {
            selector: "#fullscreen-button",
            content: 'This button allows you to switch between fullscreen and normal mode',
        },
    ]

    return (
        <>
            {/* Sessions */}
            <SessionContextProvider>
                {/* Script */}
                <EditorContextProvider>
                    {/* Editor */}
                    <ScriptContextProvider>
                        {/* Documentation */}
                        <DocumentationContextProvider>
                            {/* Tour */}
                            <TourProvider steps={steps} padding={{
                                mask: 1,
                                popover: 5
                            }} badgeContent={({totalSteps, currentStep}) => currentStep + 1 + "/" + totalSteps}>
                                {/* Loading screen */}
                                <LoadingScreenComponent>
                                    {/* Actual content */}
                                    <HeaderComponent/>
                                    <BlocklyEditor/>
                                    <DocumentationModal/>

                                    {/* Footer */}
                                    {!isFullScreen && <FooterComponent/>}
                                </LoadingScreenComponent>
                            </TourProvider>
                        </DocumentationContextProvider>
                    </ScriptContextProvider>
                </EditorContextProvider>
            </SessionContextProvider>

            {/* Toasts */}
            <ToastContainer style={{zIndex: 999}}/>
        </>
    );
}

export {App};