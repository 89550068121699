var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { TextInterpreter } from "../../blockly/textInterpreter";
import { validateStringField } from "../../../utils/validation";
var BookStage = /** @class */ (function () {
    function BookStage(author, pages) {
        this.name = "Book";
        this.author = author;
        this.pages = pages;
    }
    BookStage.prototype.toJson = function () {
        return __spreadArray([
            this.author
        ], this.pages, true);
    };
    return BookStage;
}());
var BookStageInterpreter = /** @class */ (function () {
    function BookStageInterpreter() {
        this.blockType = "book_stage";
    }
    BookStageInterpreter.prototype.interpret = function (block) {
        return new BookStage(block.getFieldValue("author"), TextInterpreter.interpretList(block.getInputTargetBlock("lines")));
    };
    BookStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "author")
            && TextInterpreter.validateList(block, block.getInputTargetBlock("lines"));
    };
    return BookStageInterpreter;
}());
export { BookStage, BookStageInterpreter };
