import { validateBlockInputPresence, validateNumberField } from "../../../utils/validation";
var ClickItemStage = /** @class */ (function () {
    function ClickItemStage(material, amount) {
        this.name = "ClickItem";
        this.material = material;
        this.amount = amount;
    }
    ClickItemStage.prototype.toJson = function () {
        return [
            this.material,
            this.amount
        ];
    };
    return ClickItemStage;
}());
var ClickItemStageInterpreter = /** @class */ (function () {
    function ClickItemStageInterpreter() {
        this.blockType = "click_item_stage";
    }
    ClickItemStageInterpreter.prototype.interpret = function (block) {
        var _a;
        var material = (_a = block.getInputTargetBlock("material")) === null || _a === void 0 ? void 0 : _a.getFieldValue("material");
        var amount = block.getFieldValue("amount");
        return new ClickItemStage(material, amount);
    };
    ClickItemStageInterpreter.prototype.validate = function (block) {
        return validateBlockInputPresence(block, "material")
            && validateNumberField(block, "amount", 1, 64);
    };
    return ClickItemStageInterpreter;
}());
export { ClickItemStage, ClickItemStageInterpreter };
