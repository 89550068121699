import { validateStringField } from "../../../utils/validation";
var ObjectiveStage = /** @class */ (function () {
    function ObjectiveStage(objectiveName, key) {
        this.name = "Objective";
        this.key = key;
        this.objectiveName = objectiveName;
    }
    ObjectiveStage.prototype.toJson = function () {
        return [
            this.objectiveName,
            this.key
        ];
    };
    return ObjectiveStage;
}());
var ObjectiveStageInterpreter = /** @class */ (function () {
    function ObjectiveStageInterpreter() {
        this.blockType = "objective_stage";
    }
    ObjectiveStageInterpreter.prototype.interpret = function (block) {
        return new ObjectiveStage(block.getFieldValue("name"), block.getFieldValue("key"));
    };
    ObjectiveStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "key")
            && validateStringField(block, "name");
    };
    return ObjectiveStageInterpreter;
}());
export { ObjectiveStage, ObjectiveStageInterpreter };
