import { Region } from "../../../types/minecraft";
import { validateRegion } from "../../../utils/validation";
var RegionEntryStage = /** @class */ (function () {
    function RegionEntryStage(region) {
        this.name = "RegionEntry";
        this.region = region;
    }
    RegionEntryStage.prototype.toJson = function () {
        return this.region.toJson();
    };
    return RegionEntryStage;
}());
var RegionEntryStageInterpreter = /** @class */ (function () {
    function RegionEntryStageInterpreter() {
        this.blockType = "region_entry_stage";
    }
    RegionEntryStageInterpreter.prototype.interpret = function (block) {
        return new RegionEntryStage(Region.fromBlock(block));
    };
    RegionEntryStageInterpreter.prototype.validate = function (block) {
        return validateRegion(block);
    };
    return RegionEntryStageInterpreter;
}());
export { RegionEntryStageInterpreter, RegionEntryStage };
