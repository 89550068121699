import { validateStringField } from "../../../utils/validation";
var OpenMenuStage = /** @class */ (function () {
    function OpenMenuStage(value) {
        this.name = "OpenMenu";
        this.value = value;
    }
    OpenMenuStage.prototype.toJson = function () {
        return this.value;
    };
    return OpenMenuStage;
}());
var OpenMenuStageInterpreter = /** @class */ (function () {
    function OpenMenuStageInterpreter() {
        this.blockType = "open_menu_stage";
    }
    OpenMenuStageInterpreter.prototype.interpret = function (block) {
        return new OpenMenuStage(block.getFieldValue("menu"));
    };
    OpenMenuStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "menu");
    };
    return OpenMenuStageInterpreter;
}());
export { OpenMenuStage, OpenMenuStageInterpreter };
