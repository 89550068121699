import { Region } from "../../../types/minecraft";
import { validateRegion } from "../../../utils/validation";
var NoFlyRegionStage = /** @class */ (function () {
    function NoFlyRegionStage(region) {
        this.name = "NoFlyRegion";
        this.region = region;
    }
    NoFlyRegionStage.prototype.toJson = function () {
        return this.region.toJson();
    };
    return NoFlyRegionStage;
}());
var NoFlyRegionStageInterpreter = /** @class */ (function () {
    function NoFlyRegionStageInterpreter() {
        this.blockType = "no_fly_region_stage";
    }
    NoFlyRegionStageInterpreter.prototype.interpret = function (block) {
        return new NoFlyRegionStage(Region.fromBlock(block));
    };
    NoFlyRegionStageInterpreter.prototype.validate = function (block) {
        return validateRegion(block);
    };
    return NoFlyRegionStageInterpreter;
}());
export { NoFlyRegionStage, NoFlyRegionStageInterpreter };
