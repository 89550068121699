import { validateStringField } from "../../../utils/validation";
var HideStage = /** @class */ (function () {
    function HideStage(entity) {
        this.name = "Hide";
        this.entity = entity;
    }
    HideStage.prototype.toJson = function () {
        return this.entity;
    };
    return HideStage;
}());
var HideStageInterpreter = /** @class */ (function () {
    function HideStageInterpreter() {
        this.blockType = "hide_stage";
    }
    HideStageInterpreter.prototype.interpret = function (block) {
        return new HideStage(block.getFieldValue("entity"));
    };
    HideStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "entity");
    };
    return HideStageInterpreter;
}());
export { HideStage, HideStageInterpreter };
