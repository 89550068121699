import { createEmptyWrappedStage, findByComment, findFirstByComment, insertAfter, insertBefore, moveAfter, replace } from "../../utils/wrappedStages";
import { DummyStage } from "../stages/dummy/dummyStage";
import { SkipStage } from "../stages/dummy/skipStage";
import { CommentStage } from "../stages/dummy/commentStage";
import { LeaveStage } from "../stages/dummy/leaveStage";
var SimpleIfPostProcessor = /** @class */ (function () {
    function SimpleIfPostProcessor() {
        this.triggerStage = "If";
    }
    SimpleIfPostProcessor.prototype.process = function (stages, ifBlock, triggerStageIndex) {
        if (ifBlock.comment !== "if_single")
            return stages;
        var condition = findFirstByComment(stages, "if_condition", triggerStageIndex);
        var body = findFirstByComment(stages, "if_body", triggerStageIndex);
        var elseBody = findFirstByComment(stages, "else_body", triggerStageIndex);
        if (!ifBlock || !condition || !body || elseBody) {
            return stages;
        }
        insertAfter(stages, createEmptyWrappedStage(new DummyStage()), body);
        return stages;
    };
    return SimpleIfPostProcessor;
}());
var ComplexIfPostProcessor = /** @class */ (function () {
    function ComplexIfPostProcessor() {
        this.triggerStage = "If";
    }
    ComplexIfPostProcessor.prototype.process = function (stages, ifBlock, triggerStageIndex) {
        if (ifBlock.comment !== "if_multiple")
            return stages;
        var condition = findFirstByComment(stages, "if_condition", triggerStageIndex);
        var ifBody = findByComment(stages, "if_body", triggerStageIndex);
        var elseBody = findByComment(stages, "else_body", triggerStageIndex);
        if (!ifBlock || !condition || !ifBody) {
            return stages;
        }
        // Check if script ends with this if/else block
        var hasElse = elseBody && elseBody.length > 0;
        var scriptLastStage = stages[stages.length - 1];
        var isScriptEnd = hasElse ? scriptLastStage.comment === "else_body" : scriptLastStage.comment === "if_body";
        // Head
        var headSkip = insertAfter(stages, createEmptyWrappedStage(new SkipStage("Heads")), condition);
        var headComment = insertAfter(stages, createEmptyWrappedStage(new CommentStage("Heads")), headSkip);
        ifBody.reverse();
        var headBodyTail = moveAfter(stages, ifBody, headComment);
        if (!headBodyTail)
            throw new Error("If body tail not found");
        var headLeave = insertAfter(stages, createEmptyWrappedStage(new LeaveStage()), headBodyTail);
        // Continue block
        if (!isScriptEnd && !hasElse) {
            var continueId = this.getContinueId(stages) + 1;
            insertBefore(stages, createEmptyWrappedStage(new SkipStage("Continue-".concat(continueId))), headLeave);
            this.insertContinueBlock(stages, headLeave, continueId);
        }
        // Else
        if (!hasElse) {
            insertAfter(stages, createEmptyWrappedStage(new DummyStage()), headSkip);
            return stages;
        }
        elseBody.reverse();
        // Tail
        insertAfter(stages, createEmptyWrappedStage(new SkipStage("Tails")), headSkip);
        var tailComment = insertAfter(stages, createEmptyWrappedStage(new CommentStage("Tails")), headLeave);
        var tailBodyTail = moveAfter(stages, elseBody, tailComment);
        if (!tailBodyTail)
            throw new Error("Else body tail not found");
        var tailLeave = insertAfter(stages, createEmptyWrappedStage(new LeaveStage()), tailBodyTail);
        // Continue block
        if (!isScriptEnd) {
            var continueId = this.getContinueId(stages);
            insertBefore(stages, createEmptyWrappedStage(new SkipStage("Continue-".concat(continueId))), tailLeave);
            replace(stages, headLeave, createEmptyWrappedStage(new SkipStage("Continue-".concat(continueId))));
            this.insertContinueBlock(stages, tailLeave, continueId);
        }
        return stages;
    };
    ComplexIfPostProcessor.prototype.getContinueId = function (stages) {
        return stages.filter(function (s) { return s.stage instanceof CommentStage && s.stage.value.startsWith("Continue-"); }).length;
    };
    ComplexIfPostProcessor.prototype.insertContinueBlock = function (stages, lastBlock, id) {
        return replace(stages, lastBlock, createEmptyWrappedStage(new CommentStage("Continue-".concat(id))));
    };
    return ComplexIfPostProcessor;
}());
export { ComplexIfPostProcessor, SimpleIfPostProcessor };
