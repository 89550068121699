import { validateStringField } from "../../../utils/validation";
var HasTeamStage = /** @class */ (function () {
    function HasTeamStage(teamName) {
        this.name = "HasTeam";
        this.teamName = teamName;
    }
    HasTeamStage.prototype.toJson = function () {
        return this.teamName;
    };
    return HasTeamStage;
}());
var HasTeamStageInterpreter = /** @class */ (function () {
    function HasTeamStageInterpreter() {
        this.blockType = "has_team_stage";
    }
    HasTeamStageInterpreter.prototype.interpret = function (block) {
        return new HasTeamStage(block.getFieldValue("team_name"));
    };
    HasTeamStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "team_name");
    };
    return HasTeamStageInterpreter;
}());
export { HasTeamStageInterpreter, HasTeamStage };
