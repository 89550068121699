import { validateStringField } from "../../../utils/validation";
var KeyPressedStage = /** @class */ (function () {
    function KeyPressedStage(key) {
        this.name = "KeyPressed";
        this.key = key;
    }
    KeyPressedStage.prototype.toJson = function () {
        return this.key;
    };
    return KeyPressedStage;
}());
var KeyPressedStageInterpreter = /** @class */ (function () {
    function KeyPressedStageInterpreter() {
        this.blockType = "key_pressed_stage";
    }
    KeyPressedStageInterpreter.prototype.interpret = function (block) {
        return new KeyPressedStage(block.getFieldValue("key"));
    };
    KeyPressedStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "key");
    };
    return KeyPressedStageInterpreter;
}());
export { KeyPressedStageInterpreter };
