import { Vector } from "../../../types/minecraft";
import { validateNumberField, validateVector } from "../../../utils/validation";
var TrackStage = /** @class */ (function () {
    function TrackStage(start, end, speed) {
        this.name = "Track";
        this.start = start;
        this.end = end;
        this.speed = speed;
    }
    TrackStage.prototype.toJson = function () {
        return [
            this.start.x,
            this.start.y,
            this.start.z,
            this.end.x,
            this.end.y,
            this.end.z,
            this.speed
        ];
    };
    return TrackStage;
}());
var TrackStageInterpreter = /** @class */ (function () {
    function TrackStageInterpreter() {
        this.blockType = "track_stage";
    }
    TrackStageInterpreter.prototype.interpret = function (block) {
        return new TrackStage(Vector.fromBlock(block, "start_"), Vector.fromBlock(block, "end_"), parseInt(block.getFieldValue("speed")));
    };
    TrackStageInterpreter.prototype.validate = function (block) {
        return validateVector(block, "start_")
            && validateVector(block, "end_")
            && validateNumberField(block, "speed", 1, 10);
    };
    return TrackStageInterpreter;
}());
export { TrackStageInterpreter };
