import {createContext, FC, useContext, useState} from "react";
import {WithChildren} from "../../utils/utils";
import {DocumentationContextProps, initialDocumentationContext, StageDocumentation} from "./_models";

const DocumentationContext = createContext<DocumentationContextProps>(initialDocumentationContext);

const DocumentationContextProvider: FC<WithChildren> = ({children}) => {
    const [stage, setStage] = useState<StageDocumentation | null>(null)

    return (
        <DocumentationContext.Provider
            value={{
                setShownStage: setStage,
                shownStage: stage,
                isStageShown: stage !== null
            }}
        >
            {children}
        </DocumentationContext.Provider>
    )
}

const useDocumentationContext = () => useContext(DocumentationContext);

export {DocumentationContext, DocumentationContextProvider, useDocumentationContext}