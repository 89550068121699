import { validateStringField } from "../../../utils/validation";
var CommentStage = /** @class */ (function () {
    function CommentStage(value) {
        this.name = "Comment";
        this.value = value;
    }
    CommentStage.prototype.toJson = function () {
        return this.value;
    };
    return CommentStage;
}());
var CommentStageInterpreter = /** @class */ (function () {
    function CommentStageInterpreter() {
        this.blockType = "comment_stage";
    }
    CommentStageInterpreter.prototype.interpret = function (block) {
        return new CommentStage(block.getFieldValue("value"));
    };
    CommentStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "value");
    };
    return CommentStageInterpreter;
}());
export { CommentStage, CommentStageInterpreter };
