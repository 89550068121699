var StopTimerStage = /** @class */ (function () {
    function StopTimerStage() {
        this.name = "StopTimer";
    }
    StopTimerStage.prototype.toJson = function () {
        return [];
    };
    return StopTimerStage;
}());
var StopTimerStageInterpreter = /** @class */ (function () {
    function StopTimerStageInterpreter() {
        this.blockType = "stop_timer_stage";
    }
    StopTimerStageInterpreter.prototype.interpret = function () {
        return new StopTimerStage();
    };
    return StopTimerStageInterpreter;
}());
export { StopTimerStage, StopTimerStageInterpreter };
