import { Location } from "../../../types/minecraft";
import { validateLocation, validateStringField } from "../../../utils/validation";
var CumulativePickupHeadStage = /** @class */ (function () {
    function CumulativePickupHeadStage(location, name, texture, signature) {
        this.name = "CumulativePickupHead";
        this.location = location;
        this.name_ = name;
        this.texture = texture;
        this.signature = signature;
    }
    CumulativePickupHeadStage.prototype.toJson = function () {
        return [
            this.location.x,
            this.location.y,
            this.location.z,
            this.location.yaw,
            this.name_,
            this.texture,
            this.signature
        ];
    };
    return CumulativePickupHeadStage;
}());
var CumulativePickupHeadStageInterpreter = /** @class */ (function () {
    function CumulativePickupHeadStageInterpreter() {
        this.blockType = "cumulative_pickup_head_stage";
    }
    CumulativePickupHeadStageInterpreter.prototype.interpret = function (block) {
        return new CumulativePickupHeadStage(Location.fromBlock(block), block.getFieldValue("name"), block.getFieldValue("texture"), block.getFieldValue("signature"));
    };
    CumulativePickupHeadStageInterpreter.prototype.validate = function (block) {
        return validateLocation(block)
            && validateStringField(block, "name")
            && validateStringField(block, "texture")
            && validateStringField(block, "signature");
    };
    return CumulativePickupHeadStageInterpreter;
}());
export { CumulativePickupHeadStage, CumulativePickupHeadStageInterpreter };
