import { validateNumberField, validateStringField } from "../../../utils/validation";
import { TextInterpreter } from "../../blockly/textInterpreter";
var NotCompletedStage = /** @class */ (function () {
    function NotCompletedStage(scriptKey, hours, minutes, seconds, message) {
        this.name = "NotCompleted";
        this.scriptKey = scriptKey;
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
        this.message = message;
    }
    NotCompletedStage.prototype.toJson = function () {
        return [
            this.scriptKey,
            this.hours,
            this.minutes,
            this.seconds,
            this.message
        ];
    };
    return NotCompletedStage;
}());
var NotCompletedStageInterpreter = /** @class */ (function () {
    function NotCompletedStageInterpreter() {
        this.blockType = "not_completed_stage";
    }
    NotCompletedStageInterpreter.prototype.interpret = function (block) {
        var message = TextInterpreter.interpret(block.getInputTargetBlock("message"));
        if (message == null)
            throw new Error("Message is null");
        return new NotCompletedStage(block.getFieldValue("script_key"), block.getFieldValue("hours"), block.getFieldValue("minutes"), block.getFieldValue("seconds"), message);
    };
    NotCompletedStageInterpreter.prototype.validate = function (block) {
        if (!validateStringField(block, "script_key")
            || TextInterpreter.validate(block, block.getInputTargetBlock("message")))
            return false;
        if (!isNaN(Number(block.getFieldValue("seconds"))))
            return validateNumberField(block, "seconds", 0, 60);
        if (!isNaN(Number(block.getFieldValue("minutes"))))
            return validateNumberField(block, "minutes", 0, 60);
        if (!isNaN(Number(block.getFieldValue("hours"))))
            return validateNumberField(block, "hours", 0, 24);
        block.setWarningText("At least one of the fields must be a number");
        return false;
    };
    return NotCompletedStageInterpreter;
}());
export { NotCompletedStage, NotCompletedStageInterpreter };
