import { Vector } from "../../../types/minecraft";
import { validateBlockInputPresence, validateBooleanField, validateStringField, validateVector } from "../../../utils/validation";
var CumulativePickupItemStage = /** @class */ (function () {
    function CumulativePickupItemStage(location, name, material, fixed) {
        this.name = "CumulativePickupItem";
        this.location = location;
        this.name_ = name;
        this.material = material;
        this.fixed = fixed;
    }
    CumulativePickupItemStage.prototype.toJson = function () {
        return [
            this.location.x,
            this.location.y,
            this.location.z,
            this.name_,
            this.material,
            this.fixed
        ];
    };
    return CumulativePickupItemStage;
}());
var CumulativePickupItemStageInterpreter = /** @class */ (function () {
    function CumulativePickupItemStageInterpreter() {
        this.blockType = "cumulative_pickup_item_stage";
    }
    CumulativePickupItemStageInterpreter.prototype.interpret = function (block) {
        var _a;
        var material = (_a = block.getInputTargetBlock("material")) === null || _a === void 0 ? void 0 : _a.getFieldValue("material");
        var name = block.getFieldValue("name");
        var location = Vector.fromBlock(block);
        var fixed = block.getFieldValue("fixed");
        return new CumulativePickupItemStage(location, name, material, fixed);
    };
    CumulativePickupItemStageInterpreter.prototype.validate = function (block) {
        return validateBlockInputPresence(block, "material")
            && validateStringField(block, "name")
            && validateBooleanField(block, "strict")
            && validateVector(block);
    };
    return CumulativePickupItemStageInterpreter;
}());
export { CumulativePickupItemStage, CumulativePickupItemStageInterpreter };
