import {WorkspaceSvg} from "react-blockly";
import {Script} from "../script/ScriptModel";
import {SetStateAction} from "react";

type EditorContextProps = {
    workspace: WorkspaceSvg | null;
    setWorkspace: (workspace: WorkspaceSvg | null) => void;
    loadScript: (script: Script) => void;
    interpret: () => string | null;
    setScriptState: (state: SetStateAction<"unchanged" | "modified" | "saving">) => void;
    scriptState: string;
}

const initialEditorContext: EditorContextProps = {
    workspace: null,
    setWorkspace: () => null,
    loadScript: () => null,
    interpret: () => null,
    setScriptState: () => null,
    scriptState: "unchanged"
}

export {initialEditorContext};
export type {EditorContextProps}
