import { validateStringField } from "../../../utils/validation";
var DescriptionStage = /** @class */ (function () {
    function DescriptionStage(value) {
        this.name = "Description";
        this.value = value;
    }
    DescriptionStage.prototype.toJson = function () {
        return this.value;
    };
    return DescriptionStage;
}());
var DescriptionStageInterpreter = /** @class */ (function () {
    function DescriptionStageInterpreter() {
        this.blockType = "description_stage";
    }
    DescriptionStageInterpreter.prototype.interpret = function (block) {
        return new DescriptionStage(block.getFieldValue("value"));
    };
    DescriptionStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "value");
    };
    return DescriptionStageInterpreter;
}());
export { DescriptionStage, DescriptionStageInterpreter };
