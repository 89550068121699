import { validateBlockInputPresence, validateNumberField, validateStringField } from "../utils/validation";
var DialogMessage = /** @class */ (function () {
    function DialogMessage(sender, message) {
        this.message = message;
        this.sender = sender.toLowerCase() === "player" ? "[PLAYER]" : sender;
    }
    return DialogMessage;
}());
var Pokemon = /** @class */ (function () {
    function Pokemon(species, skin, level, moves, gender, ability, nature, item, ivs, evs) {
        this.species = species;
        this.skin = skin;
        this.level = level;
        this.moves = moves;
        this.gender = gender;
        this.ability = ability;
        this.nature = nature;
        this.item = item;
        this.ivs = ivs;
        this.evs = evs;
    }
    Pokemon.validate = function (block) {
        return validateStringField(block, "species")
            && validateNumberField(block, "level", 1, 100);
    };
    Pokemon.fromBlock = function (block) {
        var _a, _b, _c, _d, _e, _f;
        var species = block.getFieldValue("species");
        var skin = block.getFieldValue("skin");
        var level = block.getFieldValue("level");
        if (!species || !level || !skin)
            return null;
        var gender = (_a = block.getFieldValue("gender")) !== null && _a !== void 0 ? _a : "male";
        var ability = (_b = block.getFieldValue("ability")) !== null && _b !== void 0 ? _b : "";
        var nature = (_c = block.getFieldValue("nature")) !== null && _c !== void 0 ? _c : "";
        var ivs = (_d = IVs.fromBlock(block.getInputTargetBlock("ivs"))) !== null && _d !== void 0 ? _d : new IVs(0, 0, 0, 0, 0, 0);
        var evs = (_e = EVs.fromBlock(block.getInputTargetBlock("evs"))) !== null && _e !== void 0 ? _e : new EVs(0, 0, 0, 0, 0, 0);
        var item = (_f = block.getFieldValue("item")) !== null && _f !== void 0 ? _f : undefined;
        if (item === "none")
            item = undefined;
        var movesBlock = block.getInputTargetBlock("moves");
        var moves = [];
        if (movesBlock) {
            if (movesBlock.type == 'pokemon_move_object')
                moves.push(movesBlock.getFieldValue("move"));
            else if (movesBlock.type == 'lists_create_with')
                movesBlock.inputList
                    .filter(function (value) { return value.connection != null; })
                    .map(function (input) { var _a; return (_a = input.connection) === null || _a === void 0 ? void 0 : _a.targetBlock(); })
                    .filter(function (moveBlock) { return (moveBlock === null || moveBlock === void 0 ? void 0 : moveBlock.type) == 'pokemon_move_object'; })
                    .map(function (moveBlock) { return moves.push(moveBlock.getFieldValue("move")); });
        }
        return new Pokemon(species, skin, level, moves, gender, ability, nature, item, ivs, evs);
    };
    Pokemon.prototype.toJson = function () {
        if (!this.item)
            return {
                "species": this.species,
                "skin": this.skin,
                "level": this.level,
                "moves": this.moves,
                "gender": this.gender,
                "ability": this.ability,
                "nature": this.nature,
                "ivs": this.ivs.toJson(),
                "evs": this.evs.toJson()
            };
        return {
            "species": this.species,
            "skin": this.skin,
            "level": this.level,
            "moves": this.moves,
            "gender": this.gender,
            "ability": this.ability,
            "nature": this.nature,
            "item": this.item,
            "ivs": this.ivs.toJson(),
            "evs": this.evs.toJson()
        };
    };
    return Pokemon;
}());
var IVs = /** @class */ (function () {
    function IVs(hp, attack, specialAttack, defense, specialDefense, speed) {
        this.hp = hp;
        this.attack = attack;
        this.specialAttack = specialAttack;
        this.defense = defense;
        this.specialDefense = specialDefense;
        this.speed = speed;
    }
    IVs.prototype.toJson = function () {
        return {
            "hp": this.hp,
            "attack": this.attack,
            "special_attack": this.specialAttack,
            "defense": this.defense,
            "special_defense": this.specialDefense,
            "speed": this.speed
        };
    };
    IVs.validate = function (parentBlock) {
        if (!validateBlockInputPresence(parentBlock, "ivs"))
            return false;
        var block = parentBlock.getInputTargetBlock("ivs");
        return validateNumberField(block, "attack", 0, 100)
            && validateNumberField(block, "defense", 0, 100)
            && validateNumberField(block, "speed", 0, 100)
            && validateNumberField(block, "special_attack", 0, 100)
            && validateNumberField(block, "special_defense", 0, 100)
            && validateNumberField(block, "hp", 0, 100);
    };
    IVs.fromBlock = function (block) {
        if (block.type !== "pokemon_ivs_object")
            throw new Error("Invalid block type (IVs)");
        var hp = block.getFieldValue("hp");
        var attack = block.getFieldValue("attack");
        var specialAttack = block.getFieldValue("special_attack");
        var defense = block.getFieldValue("defense");
        var specialDefense = block.getFieldValue("special_defense");
        var speed = block.getFieldValue("speed");
        return new IVs(hp, attack, specialAttack, defense, specialDefense, speed);
    };
    return IVs;
}());
var EVs = /** @class */ (function () {
    function EVs(hp, attack, specialAttack, defense, specialDefense, speed) {
        this.hp = hp;
        this.attack = attack;
        this.specialAttack = specialAttack;
        this.defense = defense;
        this.specialDefense = specialDefense;
        this.speed = speed;
    }
    EVs.prototype.toJson = function () {
        return {
            "hp": this.hp,
            "attack": this.attack,
            "special_attack": this.specialAttack,
            "defense": this.defense,
            "special_defense": this.specialDefense,
            "speed": this.speed
        };
    };
    EVs.validate = function (parentBlock) {
        if (!validateBlockInputPresence(parentBlock, "evs"))
            return false;
        var block = parentBlock.getInputTargetBlock("evs");
        return validateNumberField(block, "attack", 0, 100)
            && validateNumberField(block, "defense", 0, 100)
            && validateNumberField(block, "speed", 0, 100)
            && validateNumberField(block, "special_attack", 0, 100)
            && validateNumberField(block, "special_defense", 0, 100)
            && validateNumberField(block, "hp", 0, 100);
    };
    EVs.fromBlock = function (block) {
        if (block.type !== "pokemon_evs_object")
            throw new Error("Invalid block type (EVs)");
        var hp = block.getFieldValue("hp");
        var attack = block.getFieldValue("attack");
        var specialAttack = block.getFieldValue("special_attack");
        var defense = block.getFieldValue("defense");
        var specialDefense = block.getFieldValue("special_defense");
        var speed = block.getFieldValue("speed");
        return new EVs(hp, attack, specialAttack, defense, specialDefense, speed);
    };
    return EVs;
}());
export { DialogMessage, Pokemon, IVs, EVs };
