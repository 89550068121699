var LeaveStage = /** @class */ (function () {
    function LeaveStage() {
        this.name = "Leave";
    }
    LeaveStage.prototype.toJson = function () {
        return [];
    };
    return LeaveStage;
}());
var LeaveStageInterpreter = /** @class */ (function () {
    function LeaveStageInterpreter() {
        this.blockType = "leave_stage";
    }
    LeaveStageInterpreter.prototype.interpret = function () {
        return new LeaveStage();
    };
    return LeaveStageInterpreter;
}());
export { LeaveStage, LeaveStageInterpreter };
