class Session {

    id: string
    account_id: string
    ip: string
    created_at: string
    ended_at: string

    constructor(id: string, account_id: string, ip: string, created_at: string, ended_at: string) {
        this.id = id;
        this.account_id = account_id;
        this.ip = ip;
        this.created_at = created_at;
        this.ended_at = ended_at;
    }
}

type SessionContextProps = {
    session: Session | null | undefined
    isLoading: boolean
    isIpLoading: boolean
    hasSession: () => boolean
    refreshSession: () => void
    onSessionUpdated: (session: Session) => void
    onSessionExpired: () => void
    hasCompletedInitialTour: boolean
    setHasCompletedInitialTour: (hasDoneInitialTour: boolean) => void
}

const initialSessionContext: SessionContextProps = {
    session: null,
    isLoading: true,
    isIpLoading: true,
    hasSession: () => false,
    refreshSession: () => {
    },
    onSessionUpdated: (session: Session) => {
    },
    onSessionExpired: () => {
    },
    hasCompletedInitialTour: false,
    setHasCompletedInitialTour: (hasCompletedInitialTour: boolean) => {
    }
}

export {Session, initialSessionContext};
export type {SessionContextProps};
