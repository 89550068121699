var UnrideStage = /** @class */ (function () {
    function UnrideStage() {
        this.name = "Unride";
    }
    UnrideStage.prototype.toJson = function () {
        return [];
    };
    return UnrideStage;
}());
var UnrideStageInterpreter = /** @class */ (function () {
    function UnrideStageInterpreter() {
        this.blockType = "unride_stage";
    }
    UnrideStageInterpreter.prototype.interpret = function (block) {
        return new UnrideStage();
    };
    return UnrideStageInterpreter;
}());
export { UnrideStageInterpreter };
