import React, {useState} from "react";
import {useQuery} from "react-query";
import {QUERIES} from "../../utils/consts";
import {toast} from "react-toastify";
import {useSessionContext} from "../sessions/SessionContextProvider";
import {useEditorContext} from "../editor/EditorContextProvider";
import Blockly from "blockly";
import {BlocklyInterpreter} from "@pokefind/script-interpreter/dist/interpreter";
import {useScriptContext} from "../script/ScriptContextProvider";
import {Script} from "../script/ScriptModel";
import {findScriptsByAccountId} from "../script/_requests";
import {Menu, Transition} from "@headlessui/react";
import CreateScriptComponent from "./CreateScriptComponent";

const ScriptListComponent = () => {
    const {session, hasSession} = useSessionContext()
    const {workspace} = useEditorContext()
    const {
        script: currentScript,
        saveScript,
        setScript,
        isScriptLoading,
        areScriptsLoading
    } = useScriptContext()

    const [scripts, setScripts] = useState<Script[]>([])

    const [createScriptModalVisible, setCreateScriptModalVisible] = useState(false)

    const {
        isLoading
    } = useQuery(QUERIES.GET_SCRIPTS, () => findScriptsByAccountId(session?.account_id as string), {
        cacheTime: 600,
        enabled: hasSession(),
        onError: (err) => toast.error(`Failed to get session: ${err}`),
        onSuccess: (data) => {
            if (data)
                setScripts(data)
            else
                toast.error("Failed to get scripts")
        }
    });

    const loadScript = (script: Script) => {
        workspace?.getInjectionDiv().classList.add("blur-md")
        const promise = new Promise<void>(async (resolve, reject) => {
            if (script && workspace) {
                const blockly = JSON.stringify(Blockly.serialization.workspaces.save(workspace));
                const pokefind = new BlocklyInterpreter().interpret(workspace)
                await saveScript(blockly, pokefind || "{}")
                    .catch(reason => {
                        toast.dark(`Failed to save script: ${reason}`, {icon: "🚨"})
                        workspace?.getInjectionDiv().classList.remove("blur-md")
                        reject()
                    })
            }
            setScript(script)
            workspace?.getInjectionDiv().classList.remove("blur-md")
            resolve()
        })

        toast.promise(promise, {
            pending: `Loading ${script.name}...`,
            success: `${script.name} loaded!`,
            error: `Failed to load ${script.name}!`,
        }, {
            theme: "dark",
            icon: (status) => {
                if (status.type === "default")
                    return "🔄"
                else if (status.type === "success")
                    return "🎉"
                else if (status.type === "error")
                    return "🚨"
                return "🤔"
            }
        }).then(() => {
        })
    }

    if (isLoading || isScriptLoading || areScriptsLoading)
        return (
            <button disabled type="button"
                    className="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
                <svg aria-hidden="true" role="status"
                     className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101"
                     fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"/>
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#1C64F2"/>
                </svg>
                Loading...
            </button>
        )

    if (scripts.length === 0)
        return (<></>)

    return (
        <>
            <div className="relative inline-block text-left">
                <Menu>
                    {({open}) => (
                        <>
                            <Menu.Button
                                id='script-list-button'
                                className="inline-flex w-full justify-center text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2 text-center"
                            >
                                My Scripts
                                <svg className="-mr-1 h-6 w-5 text-white-400" viewBox="0 0 20 20" fill="currentColor"
                                     aria-hidden="true">
                                    <path
                                        fillRule="evenodd"
                                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </Menu.Button>

                            <Transition
                                show={open}
                                enter="transition-opacity duration-100"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Menu.Items
                                    static
                                    className="z-10 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                >
                                    <div className="py-1" role="none">
                                        {scripts.map((script, index) => {
                                            const isCurrent = script.id === currentScript?.id;
                                            return (
                                                <Menu.Item key={index}>
                                                    {({active}) => (
                                                        <div
                                                            className={`block px-4 py-2 text-sm bg-white text-black flex ${
                                                                isCurrent ? 'bg-gray-200' : active ? 'hover:bg-gray-400' : ''
                                                            }`}
                                                            onClick={() => !isCurrent && loadScript(script)}
                                                        >
                                                            {script.name}
                                                            {isCurrent && (
                                                                <span
                                                                    className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300 ms-2">
                                                                    Current
                                                                </span>
                                                            )}
                                                        </div>
                                                    )}
                                                </Menu.Item>
                                            );
                                        })}
                                    </div>
                                    {scripts.length < 10 && (
                                        <div className="py-2">
                                            <div
                                                onClick={() => setCreateScriptModalVisible(true)}
                                                className="px-4 py-2 text-sm text-black bg-white hover:bg-gray-400 inline-flex items-center me-2 w-full rounded"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 me-2">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M12 4.5v15m7.5-7.5h-15"/>
                                                </svg>
                                                Create new
                                            </div>
                                        </div>
                                    )}
                                </Menu.Items>
                            </Transition>
                        </>
                    )}
                </Menu>
            </div>

            <CreateScriptComponent open={createScriptModalVisible} closable={true} createCallback={() => {
            }} closeCallback={() => setCreateScriptModalVisible(false)}/>
        </>
    )
}

export {ScriptListComponent}