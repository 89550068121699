import { InterpreterManager } from "./manager";
import Script from "../types/script";
import { PlaySoundStageInterpreter } from "./stages/action/playSoundStage";
import { AuthorStageInterpreter } from "./stages/dummy/authorStage";
import { TeleportStageInterpreter } from "./stages/action/teleportStage";
import { SendMessageStageInterpreter } from "./stages/action/sendMessageStage";
import { HouseEnterStageInterpreter } from "./stages/trigger/houseEnterStage";
import { OpenMenuStageInterpreter } from "./stages/action/openMenuStage";
import { ChanceStageInterpreter } from "./stages/conditions/chanceStage";
import { PlayTimeStageInterpreter } from "./stages/conditions/playTimeStage";
import { IfStageInterpreter } from "./stages/logic/ifStage";
import { DummyStageInterpreter } from "./stages/dummy/dummyStage";
import { CommentStageInterpreter } from "./stages/dummy/commentStage";
import { SkipStageInterpreter } from "./stages/dummy/skipStage";
import { LeaveStageInterpreter } from "./stages/dummy/leaveStage";
import { CommandStageInterpreter } from "./stages/action/commandStage";
import { InRegionStageInterpreter } from "./stages/trigger/inRegionStage";
import { NameStageInterpreter } from "./stages/dummy/nameStage";
import { CreateEntityStageInterpreter } from "./stages/action/createEntityStage";
import { ClickEntityStageInterpreter } from "./stages/conditions/clickEntityStage";
import { ComplexIfPostProcessor, SimpleIfPostProcessor } from "./postprocessing/IfPostProcessor";
import { AllowRejoiningStageInterpreter } from "./stages/dummy/allowRejoiningStage";
import { CheckpointStageInterpreter } from "./stages/action/checkpointStage";
import { ClickBlockStageInterpreter } from "./stages/conditions/clickBlockStage";
import { CompleteStageInterpreter } from "./stages/action/completeStage";
import { CompletedStageInterpreter } from "./stages/conditions/completedStage";
import { DescriptionStageInterpreter } from "./stages/dummy/descriptionStage";
import { DistanceTravelledStageInterpreter } from "./stages/conditions/distanceTravelledStage";
import { NotCompletedStageInterpreter } from "./stages/conditions/notCompletedStage";
import { HighlightClickEntityStageInterpreter } from "./stages/conditions/highlightClickEntityStage";
import { RegionExitStageInterpreter } from "./stages/conditions/regionExitStage";
import { RegionEntryStageInterpreter } from "./stages/conditions/regionEntryStage";
import { WaitStageInterpreter } from "./stages/action/waitStage";
import { NoFlyRegionStageInterpreter } from "./stages/dummy/noFlyRegionStage";
import { SendCompleteMessageStageInterpreter } from "./stages/action/sendCompleteMessageStage";
import { PotionStageInterpreter } from "./stages/action/potionStage";
import { HasTeamStageInterpreter } from "./stages/conditions/hasTeamStage";
import { HologramStageInterpreter } from "./stages/action/hologramStage";
import { GlobalHologramStageInterpreter } from "./stages/action/globalHologramStage";
import { ClickToStartStageInterpreter } from "./stages/trigger/clickToStartStage";
import { DialogStageInterpreter } from "./stages/action/dialogStage";
import { BookStageInterpreter } from "./stages/conditions/bookStage";
import { ChairStageInterpreter } from "./stages/action/chairStage";
import { StartTimerStageInterpreter } from "./stages/action/startTimerStage";
import { StopTimerStageInterpreter } from "./stages/action/stopTimerStage";
import { NoPokemonSummonStageInterpreter } from "./stages/dummy/noPokemonSummonStage";
import { NoPokemonSummonRegionStageInterpreter } from "./stages/dummy/noPokemonSummonRegionStage";
import { MoveEntityStageInterpreter } from "./stages/action/moveEntityStage";
import { ObjectiveStageInterpreter } from "./stages/dummy/objectiveStage";
import { CumulativePickupHeadStageInterpreter } from "./stages/conditions/cumulativePickupHeadStage";
import { ClickBlocksStageInterpreter } from "./stages/conditions/clickBlocksStage";
import { ClickItemStageInterpreter } from "./stages/trigger/clickItemStage";
import { HasItemStageInterpreter } from "./stages/conditions/hasItemStage";
import { CumulativePickupItemStageInterpreter } from "./stages/conditions/cumulativePickupItemStage";
import { BattleNPCStageInterpreter } from "./stages/action/battleNPCStage";
import { BattleWildPokemonStageInterpreter } from "./stages/action/battleWildPokemonStage";
import { KeyStageInterpreter } from "./stages/dummy/keyStage";
import { HideStageInterpreter } from "./stages/action/hideStage";
import { CreateMinecraftEntityStageInterpreter } from "./stages/action/createMinecraftEntityStage";
import { CreatePokemonEntityStageInterpreter } from "./stages/action/createPokemonEntityStage";
import { ShowStageInterpreter } from "./stages/action/showStage";
import { TeleportEntityStageInterpreter } from "./stages/action/teleportEntityStage";
import { PaintingWallStageInterpreter } from "./stages/dummy/paintingWallStage";
import { TrackStageInterpreter } from "./stages/action/trackStage";
import { AnimateEntityStageInterpreter } from "./stages/action/animateEntityStage";
import { KeyPressedStageInterpreter } from "./stages/conditions/keyPressedStage";
import { RideStageInterpreter } from "./stages/action/rideStage";
import { UnrideStageInterpreter } from "./stages/action/unrideStage";
import { PvpRegionStageInterpreter } from "./stages/dummy/pvpRegionStage";
import { RespawnStageInterpreter } from "./stages/dummy/respawnStage";
import { RespawnRegionStageInterpreter } from "./stages/dummy/respawnRegionStage";
import { BeamStageInterpreter } from "./stages/action/beamStage";
import { ControlledTrackStageInterpreter } from "./stages/action/controlledTrackStage";
var BlocklyInterpreter = /** @class */ (function () {
    function BlocklyInterpreter() {
        this.manager = new InterpreterManager();
        // Logic
        this.manager.registerInterpreter(new IfStageInterpreter());
        // Conditions
        this.manager.registerInterpreter(new ChanceStageInterpreter());
        this.manager.registerInterpreter(new PlayTimeStageInterpreter());
        this.manager.registerInterpreter(new ClickEntityStageInterpreter());
        this.manager.registerInterpreter(new ClickBlockStageInterpreter());
        this.manager.registerInterpreter(new CompletedStageInterpreter());
        this.manager.registerInterpreter(new DistanceTravelledStageInterpreter());
        this.manager.registerInterpreter(new NotCompletedStageInterpreter());
        this.manager.registerInterpreter(new ClickBlocksStageInterpreter());
        this.manager.registerInterpreter(new HighlightClickEntityStageInterpreter());
        this.manager.registerInterpreter(new RegionEntryStageInterpreter());
        this.manager.registerInterpreter(new RegionExitStageInterpreter());
        this.manager.registerInterpreter(new HasTeamStageInterpreter());
        this.manager.registerInterpreter(new BookStageInterpreter());
        this.manager.registerInterpreter(new CumulativePickupHeadStageInterpreter());
        this.manager.registerInterpreter(new HasItemStageInterpreter());
        this.manager.registerInterpreter(new CumulativePickupItemStageInterpreter());
        this.manager.registerInterpreter(new KeyPressedStageInterpreter());
        // Triggers
        this.manager.registerInterpreter(new HouseEnterStageInterpreter());
        this.manager.registerInterpreter(new InRegionStageInterpreter());
        this.manager.registerInterpreter(new ClickItemStageInterpreter());
        this.manager.registerInterpreter(new ClickToStartStageInterpreter());
        // Actions
        this.manager.registerInterpreter(new SendMessageStageInterpreter());
        this.manager.registerInterpreter(new CommandStageInterpreter());
        this.manager.registerInterpreter(new OpenMenuStageInterpreter());
        this.manager.registerInterpreter(new TeleportStageInterpreter());
        this.manager.registerInterpreter(new CreateEntityStageInterpreter());
        this.manager.registerInterpreter(new CreateMinecraftEntityStageInterpreter());
        this.manager.registerInterpreter(new CreatePokemonEntityStageInterpreter());
        this.manager.registerInterpreter(new CheckpointStageInterpreter());
        this.manager.registerInterpreter(new CompleteStageInterpreter());
        this.manager.registerInterpreter(new WaitStageInterpreter());
        this.manager.registerInterpreter(new SendCompleteMessageStageInterpreter());
        this.manager.registerInterpreter(new PotionStageInterpreter());
        this.manager.registerInterpreter(new HologramStageInterpreter());
        this.manager.registerInterpreter(new GlobalHologramStageInterpreter());
        this.manager.registerInterpreter(new DialogStageInterpreter());
        this.manager.registerInterpreter(new ChairStageInterpreter());
        this.manager.registerInterpreter(new StartTimerStageInterpreter());
        this.manager.registerInterpreter(new StopTimerStageInterpreter());
        this.manager.registerInterpreter(new MoveEntityStageInterpreter());
        this.manager.registerInterpreter(new BattleNPCStageInterpreter());
        this.manager.registerInterpreter(new BattleWildPokemonStageInterpreter());
        this.manager.registerInterpreter(new HideStageInterpreter());
        this.manager.registerInterpreter(new ShowStageInterpreter());
        this.manager.registerInterpreter(new TeleportEntityStageInterpreter());
        this.manager.registerInterpreter(new TrackStageInterpreter());
        this.manager.registerInterpreter(new ControlledTrackStageInterpreter());
        this.manager.registerInterpreter(new AnimateEntityStageInterpreter());
        this.manager.registerInterpreter(new RideStageInterpreter());
        this.manager.registerInterpreter(new UnrideStageInterpreter());
        this.manager.registerInterpreter(new BeamStageInterpreter());
        // Dummy
        this.manager.registerInterpreter(new NameStageInterpreter());
        this.manager.registerInterpreter(new DummyStageInterpreter());
        this.manager.registerInterpreter(new LeaveStageInterpreter());
        this.manager.registerInterpreter(new SkipStageInterpreter());
        this.manager.registerInterpreter(new CommentStageInterpreter());
        this.manager.registerInterpreter(new PlaySoundStageInterpreter());
        this.manager.registerInterpreter(new AuthorStageInterpreter());
        this.manager.registerInterpreter(new AllowRejoiningStageInterpreter());
        this.manager.registerInterpreter(new DescriptionStageInterpreter());
        this.manager.registerInterpreter(new NoFlyRegionStageInterpreter());
        this.manager.registerInterpreter(new NoPokemonSummonStageInterpreter());
        this.manager.registerInterpreter(new NoPokemonSummonRegionStageInterpreter());
        this.manager.registerInterpreter(new ObjectiveStageInterpreter());
        this.manager.registerInterpreter(new KeyStageInterpreter());
        this.manager.registerInterpreter(new PaintingWallStageInterpreter());
        this.manager.registerInterpreter(new PvpRegionStageInterpreter());
        this.manager.registerInterpreter(new RespawnStageInterpreter());
        this.manager.registerInterpreter(new RespawnRegionStageInterpreter());
        // Post-processing
        this.manager.registerPostProcessor(new SimpleIfPostProcessor());
        this.manager.registerPostProcessor(new ComplexIfPostProcessor());
    }
    BlocklyInterpreter.prototype.interpret = function (workspace) {
        var blocks = workspace.getAllBlocks(true);
        if (blocks.length === 0) {
            return null;
        }
        var stages = this.manager.interpretAll(blocks);
        var script = new Script("default", stages);
        return script.toJson()
            .replace(/\bTRUE\b/g, 'true')
            .replace(/\bFALSE\b/g, 'false')
            .replace(/"true"/g, 'true')
            .replace(/"false"/g, 'false');
    };
    BlocklyInterpreter.prototype.validateAll = function (workspace, onlyEnabledBlocks) {
        if (onlyEnabledBlocks === void 0) { onlyEnabledBlocks = true; }
        var blocks = workspace.getAllBlocks(true);
        if (blocks.length === 0) {
            return false;
        }
        return this.manager.validateAll(blocks, onlyEnabledBlocks);
    };
    BlocklyInterpreter.prototype.validate = function (block) {
        return this.manager.validate(block);
    };
    return BlocklyInterpreter;
}());
export { BlocklyInterpreter };
