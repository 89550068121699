import { Location } from "../../../types/minecraft";
import { validateLocation } from "../../../utils/validation";
var RespawnStage = /** @class */ (function () {
    function RespawnStage(location) {
        this.name = "Respawn";
        this.location = location;
    }
    RespawnStage.prototype.toJson = function () {
        return this.location.toJson();
    };
    return RespawnStage;
}());
var RespawnStageInterpreter = /** @class */ (function () {
    function RespawnStageInterpreter() {
        this.blockType = "respawn_stage";
    }
    RespawnStageInterpreter.prototype.interpret = function (block) {
        return new RespawnStage(Location.fromBlock(block));
    };
    RespawnStageInterpreter.prototype.validate = function (block) {
        return validateLocation(block);
    };
    return RespawnStageInterpreter;
}());
export { RespawnStageInterpreter };
