import React, {FC, Fragment, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {XCircleIcon} from "@heroicons/react/24/outline";

interface ConfirmationModalProps {
    title: string
    content: string
    open: boolean
    acceptCallback: () => void
    declineCallback: () => void
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({title, content, acceptCallback, declineCallback, open = true}) => {
    let [isOpen, setIsOpen] = useState(open)

    function closeModal() {
        setIsOpen(false)
    }

    function closeAndDecline() {
        closeModal()
        declineCallback()
    }

    function closeAndAccept() {
        closeModal()
        acceptCallback()
    }

    if (!isOpen)
        return null

    return (
        <Transition show={true} as={Fragment}>
            <Dialog
                open={true}
                onClose={declineCallback}
                className="fixed inset-0 z-100 overflow-y-auto"
                style={{zIndex: 100}}
            >
                <div className="min-h-screen px-4 text-center">
                    {/* Background overlay */}
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-30"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-30"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30"/>
                    </Transition.Child>

                    {/* Modal */}
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel
                            className="inline-block align-middle my-64 w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <div className="absolute top-0 right-0 p-4 cursor-pointer">
                                <XCircleIcon
                                    className="h-6 w-6 text-gray-600"
                                    onClick={closeModal}
                                />
                            </div>
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                                {title}
                            </Dialog.Title>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    {content}
                                </p>
                            </div>

                            <div className="mt-4">
                                <button
                                    type="button"
                                    className="inline-flex me-2 justify-center rounded-md border border-transparent bg-red-300 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                    onClick={closeAndAccept}
                                >
                                    Confirm!
                                </button>
                                <button
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                                    onClick={closeAndDecline}
                                >
                                    Cancel
                                </button>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}

export default ConfirmationModal;