import { Location } from "../../../types/minecraft";
import { validateEntityKey, validateLocation, validateStringField } from "../../../utils/validation";
var ChairStage = /** @class */ (function () {
    function ChairStage(location, entityKey) {
        this.name = "Chair";
        this.location = location;
        this.entityKey = !entityKey || entityKey === "" ? null : entityKey;
    }
    ChairStage.prototype.toJson = function () {
        if (this.entityKey)
            return [
                this.location.x,
                this.location.y,
                this.location.z,
                this.location.yaw,
                this.entityKey
            ];
        return [
            this.location.x,
            this.location.y,
            this.location.z,
            this.location.yaw
        ];
    };
    return ChairStage;
}());
var ChairStageInterpreter = /** @class */ (function () {
    function ChairStageInterpreter() {
        this.blockType = "chair_stage";
    }
    ChairStageInterpreter.prototype.interpret = function (block) {
        return new ChairStage(Location.fromBlock(block), block.getFieldValue("entity_key"));
    };
    ChairStageInterpreter.prototype.validate = function (block) {
        return validateLocation(block)
            && validateStringField(block, "entity_key")
            && validateEntityKey(block, block.getFieldValue("entity_key"));
    };
    return ChairStageInterpreter;
}());
export { ChairStage, ChairStageInterpreter };
