import { validateStringField } from "../../../utils/validation";
var ShowStage = /** @class */ (function () {
    function ShowStage(entity) {
        this.name = "Show";
        this.entity = entity;
    }
    ShowStage.prototype.toJson = function () {
        return this.entity;
    };
    return ShowStage;
}());
var ShowStageInterpreter = /** @class */ (function () {
    function ShowStageInterpreter() {
        this.blockType = "show_stage";
    }
    ShowStageInterpreter.prototype.interpret = function (block) {
        return new ShowStage(block.getFieldValue("entity"));
    };
    ShowStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "entity");
    };
    return ShowStageInterpreter;
}());
export { ShowStage, ShowStageInterpreter };
