import { Location } from "../../../types/minecraft";
import { validateBooleanField, validateLocation, validateNumberField, validateStringField } from "../../../utils/validation";
var CreatePokemonEntityStage = /** @class */ (function () {
    function CreatePokemonEntityStage(key, displayName, pokemon, look, speed, location) {
        this.name = "CreateEntity";
        this.key = key;
        this.displayName = displayName;
        this.look = look;
        this.pokemon = pokemon;
        this.speed = speed;
        this.location = location;
    }
    CreatePokemonEntityStage.prototype.toJson = function () {
        return {
            key: this.key,
            name: this.displayName,
            look: this.look,
            speed: this.speed,
            pokemon: this.pokemon,
            "location": [
                this.location.x,
                this.location.y,
                this.location.z,
                this.location.yaw,
                this.location.pitch
            ]
        };
    };
    return CreatePokemonEntityStage;
}());
var CreatePokemonEntityStageInterpreter = /** @class */ (function () {
    function CreatePokemonEntityStageInterpreter() {
        this.blockType = "create_pokemon_entity_stage";
    }
    CreatePokemonEntityStageInterpreter.prototype.interpret = function (block) {
        var key = block.getFieldValue("key");
        var displayName = block.getFieldValue("name");
        var pokemon = block.getFieldValue("species");
        var look = block.getFieldValue("look");
        var speed = parseFloat(block.getFieldValue("speed"));
        var location = Location.fromBlock(block);
        return new CreatePokemonEntityStage(key, displayName, pokemon, look, speed, location);
    };
    CreatePokemonEntityStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "key")
            && validateStringField(block, "name")
            && validateStringField(block, "species")
            && validateBooleanField(block, "look")
            && validateNumberField(block, "speed", 0, 10)
            && validateLocation(block);
    };
    return CreatePokemonEntityStageInterpreter;
}());
export { CreatePokemonEntityStage, CreatePokemonEntityStageInterpreter };
