import { validateStringField } from "../../../utils/validation";
var NameStage = /** @class */ (function () {
    function NameStage(value) {
        this.name = "Name";
        this.value = value;
    }
    NameStage.prototype.toJson = function () {
        return [this.value];
    };
    return NameStage;
}());
var NameStageInterpreter = /** @class */ (function () {
    function NameStageInterpreter() {
        this.blockType = "name_stage";
    }
    NameStageInterpreter.prototype.interpret = function (block) {
        return new NameStage(block.getFieldValue("script_name"));
    };
    NameStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "script_name");
    };
    return NameStageInterpreter;
}());
export { NameStage, NameStageInterpreter };
