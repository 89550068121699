var ClickToStartStage = /** @class */ (function () {
    function ClickToStartStage() {
        this.name = "ClickToStart";
    }
    ClickToStartStage.prototype.toJson = function () {
        return [];
    };
    return ClickToStartStage;
}());
var ClickToStartStageInterpreter = /** @class */ (function () {
    function ClickToStartStageInterpreter() {
        this.blockType = "click_to_start_stage";
    }
    ClickToStartStageInterpreter.prototype.interpret = function () {
        return new ClickToStartStage();
    };
    return ClickToStartStageInterpreter;
}());
export { ClickToStartStage, ClickToStartStageInterpreter };
