import { validateNumberField, validateStringField } from "../../../utils/validation";
var PotionStage = /** @class */ (function () {
    function PotionStage(effect, strength, duration) {
        this.name = "Potion";
        this.effect = effect;
        this.strength = strength;
        this.duration = duration;
    }
    PotionStage.prototype.toJson = function () {
        if (!isNaN(this.strength) && this.strength > 0)
            return [
                this.effect,
                this.duration,
                this.strength
            ];
        return [
            this.effect,
            this.duration
        ];
    };
    return PotionStage;
}());
var PotionStageInterpreter = /** @class */ (function () {
    function PotionStageInterpreter() {
        this.blockType = "potion_stage";
    }
    PotionStageInterpreter.prototype.interpret = function (block) {
        return new PotionStage(block.getFieldValue("effect"), block.getFieldValue("strength"), block.getFieldValue("duration"));
    };
    PotionStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "effect")
            && validateNumberField(block, "duration", 1, 100000)
            && validateNumberField(block, "strength", 1, 5);
    };
    return PotionStageInterpreter;
}());
export { PotionStageInterpreter, PotionStage };
