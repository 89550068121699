type ThemeContextProps = {
    toggleDarkMode: () => void,
    isDarkMode: boolean,
    toggleFullScreen: () => void
    isFullScreen: boolean
}

const initialThemeContext: ThemeContextProps = {
    toggleDarkMode: () => {
    },
    isDarkMode: false,
    toggleFullScreen: () => {
    },
    isFullScreen: false
}

export {initialThemeContext};
export type {ThemeContextProps};

