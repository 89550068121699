import { Vector } from "../../../types/minecraft";
import { validateBooleanField, validateEntityKey, validateNumberField, validateStringField, validateVector } from "../../../utils/validation";
var MoveEntityStage = /** @class */ (function () {
    function MoveEntityStage(entity, location, speed, collisions) {
        this.name = "MoveEntity";
        this.entity = entity;
        this.location = location;
        this.speed = speed;
        this.collisions = collisions;
    }
    MoveEntityStage.prototype.toJson = function () {
        return [
            this.entity,
            this.location.x,
            this.location.y,
            this.location.z,
            this.speed,
            this.collisions
        ];
    };
    return MoveEntityStage;
}());
var MoveEntityStageInterpreter = /** @class */ (function () {
    function MoveEntityStageInterpreter() {
        this.blockType = "move_entity_stage";
    }
    MoveEntityStageInterpreter.prototype.interpret = function (block) {
        return new MoveEntityStage(block.getFieldValue("entity_key"), Vector.fromBlock(block), block.getFieldValue("speed"), block.getFieldValue("collisions_enabled"));
    };
    MoveEntityStageInterpreter.prototype.validate = function (block) {
        return validateVector(block)
            && validateStringField(block, "entity_key")
            && validateEntityKey(block, block.getFieldValue("entity_key"))
            && validateNumberField(block, "speed", 0, 10)
            && validateBooleanField(block, "collisions_enabled");
    };
    return MoveEntityStageInterpreter;
}());
export { MoveEntityStageInterpreter, MoveEntityStage };
