var NoPokemonSummonStage = /** @class */ (function () {
    function NoPokemonSummonStage() {
        this.name = "NoPokemonSummon";
    }
    NoPokemonSummonStage.prototype.toJson = function () {
        return [];
    };
    return NoPokemonSummonStage;
}());
var NoPokemonSummonStageInterpreter = /** @class */ (function () {
    function NoPokemonSummonStageInterpreter() {
        this.blockType = "no_pokemon_summon_stage";
    }
    NoPokemonSummonStageInterpreter.prototype.interpret = function () {
        return new NoPokemonSummonStage();
    };
    return NoPokemonSummonStageInterpreter;
}());
export { NoPokemonSummonStageInterpreter, NoPokemonSummonStage };
