import { validateStringField } from "../../../utils/validation";
var PlaySoundStage = /** @class */ (function () {
    function PlaySoundStage(value) {
        this.name = "PlaySound";
        this.value = value;
    }
    PlaySoundStage.prototype.toJson = function () {
        return [
            this.value,
            1,
            1
        ];
    };
    return PlaySoundStage;
}());
var PlaySoundStageInterpreter = /** @class */ (function () {
    function PlaySoundStageInterpreter() {
        this.blockType = "play_sound_stage";
    }
    PlaySoundStageInterpreter.prototype.interpret = function (block) {
        return new PlaySoundStage(block.getFieldValue("sound"));
    };
    PlaySoundStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "sound");
    };
    return PlaySoundStageInterpreter;
}());
export { PlaySoundStage, PlaySoundStageInterpreter };
// Path: src/types/stages/playSoundStage.ts
