var SendCompleteMessageStage = /** @class */ (function () {
    function SendCompleteMessageStage() {
        this.name = "SendCompleteMessage";
    }
    SendCompleteMessageStage.prototype.toJson = function () {
        return [];
    };
    return SendCompleteMessageStage;
}());
var SendCompleteMessageStageInterpreter = /** @class */ (function () {
    function SendCompleteMessageStageInterpreter() {
        this.blockType = "send_complete_message_stage";
    }
    SendCompleteMessageStageInterpreter.prototype.interpret = function () {
        return new SendCompleteMessageStage();
    };
    return SendCompleteMessageStageInterpreter;
}());
export { SendCompleteMessageStageInterpreter, SendCompleteMessageStage };
