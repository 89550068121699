import axios from "axios";
import {Script} from "./ScriptModel";

const API_URL = 'https://housing.pokefind.co';

const saveScript = async (accountId: string, name: string, blocklyJson: string, pokefindJson: string): Promise<string> => {
    const url = `${API_URL}/createScript`;
    return axios.post(url, {
        "account_id": accountId,
        "name": name,
        "blocky": blocklyJson,
        "pokefind": pokefindJson
    }).then(value => value.data.message)
}

const findScriptByName = async (name: string): Promise<Script> => {
    const url = `${API_URL}/getScript`;
    return await axios.post(url, {"name": name})
        .then(value => value.data.message)
        .then(value => value[0])
}

const deleteScript = async (script: Script): Promise<void> => {
    const url = `${API_URL}/deleteScript`;
    return await axios.post(url, {"scriptId": script.id})
        .then(value => value.data.message)
}
const findScriptsByAccountId = async (accountId: string): Promise<Script[]> => {
    const url = `${API_URL}/getScript`;
    return await axios.post(url, {"account_id": accountId})
        .then(value => value.data.message)
        .catch(reason => {
            if (reason.response.status === 404) {
                return []
            }
            throw reason
        })
}

export {findScriptByName, saveScript, findScriptsByAccountId, deleteScript}