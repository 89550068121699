var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var BlockInterpretationException = /** @class */ (function (_super) {
    __extends(BlockInterpretationException, _super);
    function BlockInterpretationException(message, block) {
        var _this = _super.call(this, "Error while interpreting block: " + message) || this;
        _this.name = "BlockInterpreterException";
        _this.block = block;
        return _this;
    }
    return BlockInterpretationException;
}(Error));
export { BlockInterpretationException };
var NoInterpreterException = /** @class */ (function (_super) {
    __extends(NoInterpreterException, _super);
    function NoInterpreterException(blockType) {
        var _this = _super.call(this, "No interpreter found for block type: " + blockType) || this;
        _this.name = "NoInterpreterException";
        _this.blockType = blockType;
        return _this;
    }
    return NoInterpreterException;
}(Error));
export { NoInterpreterException };
