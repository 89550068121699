import { validateBlockInputPresence, validateBooleanField, validateNumberField } from "../../../utils/validation";
var HasItemStage = /** @class */ (function () {
    function HasItemStage(material, amount, strict) {
        this.name = "HasItem";
        this.material = material;
        this.amount = amount;
        this.strict = strict;
    }
    HasItemStage.prototype.toJson = function () {
        if (this.amount == 1)
            return [this.material, this.strict];
        return [
            this.material,
            this.amount,
            this.strict
        ];
    };
    return HasItemStage;
}());
var HasItemStageInterpreter = /** @class */ (function () {
    function HasItemStageInterpreter() {
        this.blockType = "has_item_stage";
    }
    HasItemStageInterpreter.prototype.interpret = function (block) {
        var _a;
        var material = (_a = block.getInputTargetBlock("material")) === null || _a === void 0 ? void 0 : _a.getFieldValue("material");
        var amount = block.getFieldValue("amount");
        var strict = block.getFieldValue("strict");
        return new HasItemStage(material, amount, strict);
    };
    HasItemStageInterpreter.prototype.validate = function (block) {
        return validateBlockInputPresence(block, "material")
            && validateNumberField(block, "amount", 1, 64)
            && validateBooleanField(block, "strict");
    };
    return HasItemStageInterpreter;
}());
export { HasItemStage, HasItemStageInterpreter };
