import { validateStringField } from "../../../utils/validation";
var CompleteStage = /** @class */ (function () {
    function CompleteStage(scriptName) {
        this.name = "Complete";
        this.scriptName = scriptName;
    }
    CompleteStage.prototype.toJson = function () {
        if (this.scriptName == null || this.scriptName == "" || this.scriptName == "default" || this.scriptName == "this")
            return [];
        return this.scriptName;
    };
    return CompleteStage;
}());
var CompleteStageInterpreter = /** @class */ (function () {
    function CompleteStageInterpreter() {
        this.blockType = "complete_stage";
    }
    CompleteStageInterpreter.prototype.interpret = function (block) {
        return new CompleteStage(block.getFieldValue("script_name"));
    };
    CompleteStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "script_name");
    };
    return CompleteStageInterpreter;
}());
export { CompleteStage, CompleteStageInterpreter };
