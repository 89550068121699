import { validateStringField } from "../../../utils/validation";
var KeyStage = /** @class */ (function () {
    function KeyStage(key) {
        this.name = "Key";
        this.key = key;
    }
    KeyStage.prototype.toJson = function () {
        return this.key;
    };
    return KeyStage;
}());
var KeyStageInterpreter = /** @class */ (function () {
    function KeyStageInterpreter() {
        this.blockType = "key_stage";
    }
    KeyStageInterpreter.prototype.interpret = function (block) {
        return new KeyStage(block.getFieldValue("key"));
    };
    KeyStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "key");
    };
    return KeyStageInterpreter;
}());
export { KeyStage, KeyStageInterpreter };
