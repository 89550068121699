import { TextInterpreter } from "../../blockly/textInterpreter";
import { validateBlockInputPresence } from "../../../utils/validation";
var SendMessageStage = /** @class */ (function () {
    function SendMessageStage(messages) {
        this.name = "SendMessage";
        this.value = messages;
    }
    SendMessageStage.prototype.toJson = function () {
        if (this.value.length === 1)
            return this.value[0];
        return this.value;
    };
    return SendMessageStage;
}());
var SendMessageStageInterpreter = /** @class */ (function () {
    function SendMessageStageInterpreter() {
        this.blockType = "send_message_stage";
    }
    SendMessageStageInterpreter.prototype.interpret = function (block) {
        var _a;
        return new SendMessageStage(((_a = block.getInputTargetBlock("message_args")) === null || _a === void 0 ? void 0 : _a.type) === "lists_create_with"
            ? TextInterpreter.interpretList(block.getInputTargetBlock("message_args"))
            : [TextInterpreter.interpret(block.getInputTargetBlock("message_args"))]);
    };
    SendMessageStageInterpreter.prototype.validate = function (block) {
        var _a;
        return validateBlockInputPresence(block, "message_args")
            && ((_a = block.getInputTargetBlock("message_args")) === null || _a === void 0 ? void 0 : _a.type) === "lists_create_with"
            ? TextInterpreter.validateList(block, block.getInputTargetBlock("message_args"))
            : TextInterpreter.validate(block, block.getInputTargetBlock("message_args"));
    };
    return SendMessageStageInterpreter;
}());
export { SendMessageStage, SendMessageStageInterpreter };
