import axios from "axios";
import {Session} from "./SessionModel";

const API_URL = 'https://housing.pokefind.co';
const API_KEY = 'Q6P2e3ezDJgPjUUPUkaY6kgBMSTNxSZX';

const initAxios = () => {
    axios.defaults.headers.common['x-api-key'] = API_KEY;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.Accept = 'application/json'
}

const findSessionByIP = async (ip: string): Promise<Session> => {
    const url = `${API_URL}/getSession`;
    return await axios.post(url, {"ip": ip})
        .then(value => value.data.message)
}

export {findSessionByIP, initAxios}