import { Vector } from "../../../types/minecraft";
import { TextInterpreter } from "../../blockly/textInterpreter";
import { validateVector } from "../../../utils/validation";
var GlobalHologramStage = /** @class */ (function () {
    function GlobalHologramStage(location, lines) {
        this.name = "GlobalHologram";
        this.location = location;
        this.lines = lines;
    }
    GlobalHologramStage.prototype.toJson = function () {
        var json = [
            this.location.x,
            this.location.y,
            this.location.z
        ];
        this.lines.forEach(function (value) { return json.push(value); });
        return json;
    };
    return GlobalHologramStage;
}());
var GlobalHologramStageInterpreter = /** @class */ (function () {
    function GlobalHologramStageInterpreter() {
        this.blockType = "global_hologram_stage";
    }
    GlobalHologramStageInterpreter.prototype.interpret = function (block) {
        var inputBlock = block.getInputTargetBlock("messages");
        return new GlobalHologramStage(Vector.fromBlock(block), ((inputBlock === null || inputBlock === void 0 ? void 0 : inputBlock.type) === 'text' ? [TextInterpreter.interpret(inputBlock)] : TextInterpreter.interpretList(inputBlock)));
    };
    GlobalHologramStageInterpreter.prototype.validate = function (block) {
        var inputBlock = block.getInputTargetBlock("messages");
        return validateVector(block)
            && (inputBlock === null || inputBlock === void 0 ? void 0 : inputBlock.type) === 'text' ? TextInterpreter.validate(block, inputBlock) : TextInterpreter.validateList(block, inputBlock);
    };
    return GlobalHologramStageInterpreter;
}());
export { GlobalHologramStageInterpreter, GlobalHologramStage };
