import { TextInterpreter } from "../../blockly/textInterpreter";
import { validateBooleanField, validateNumberField, validateStringField } from "../../../utils/validation";
import { DialogMessage } from "../../../types/pokefind";
var DialogStage = /** @class */ (function () {
    function DialogStage(messages, speed, move, nextButton) {
        this.name = "NewDialog";
        this.messages = messages;
        this.speed = speed;
        this.move = move;
        this.nextButton = nextButton;
    }
    DialogStage.prototype.toJson = function () {
        // TODO: allow key duplication
        if (this.messages.length === 1) {
            var message = this.messages[0];
            return [
                "[".concat(message.sender, ": ").concat(message.message, "]"),
                this.speed,
                this.move,
                this.nextButton
            ];
        }
        var messages = {};
        messages = '{';
        for (var _i = 0, _a = this.messages; _i < _a.length; _i++) {
            var message = _a[_i];
            messages += "\"".concat(message.sender, "\": \"").concat(message.message, "\",");
        }
        messages = messages.slice(0, -1);
        messages += '}';
        return "[".concat(messages, ", ").concat(this.speed, ", ").concat(this.move, ", ").concat(this.nextButton, "]");
    };
    return DialogStage;
}());
var DialogStageInterpreter = /** @class */ (function () {
    function DialogStageInterpreter() {
        this.blockType = "dialog_stage";
    }
    DialogStageInterpreter.prototype.interpret = function (block) {
        var messages = [];
        var messagesBlock = block.getInputTargetBlock("messages");
        if (!messagesBlock)
            throw new Error("Dialog stage must have at least one message");
        var nextBlock = messagesBlock;
        while (nextBlock && nextBlock.type === "dialog_message_object") {
            var sender = nextBlock.getFieldValue("sender");
            var message = TextInterpreter.interpret(nextBlock.getInputTargetBlock("message"));
            if (!message)
                throw new Error("Dialog stage must have at least one message");
            messages.push(new DialogMessage(sender, message));
            nextBlock = nextBlock.getNextBlock();
        }
        if (messages.length === 0)
            throw new Error("Dialog stage must have at least one message");
        return new DialogStage(messages, block.getFieldValue("speed"), block.getFieldValue("move"), block.getFieldValue("next_button"));
    };
    DialogStageInterpreter.prototype.validate = function (block) {
        var messagesBlock = block.getInputTargetBlock("messages");
        if (!messagesBlock) {
            block.setWarningText("Dialog stage must have at least one message");
            return false;
        }
        var index = 0;
        var nextBlock = messagesBlock;
        while (nextBlock && nextBlock.type === "dialog_message_object") {
            index++;
            if (!validateStringField(nextBlock, "sender", "Dialog stage must have a sender"))
                return false;
            else
                nextBlock.setWarningText(null);
            if (!TextInterpreter.validate(nextBlock, nextBlock.getInputTargetBlock("message")))
                return false;
            else
                nextBlock.setWarningText(null);
            nextBlock = nextBlock.getNextBlock();
        }
        if (index < 1) {
            block.setWarningText("Dialog stage must have at least two messages.");
            return false;
        }
        return validateNumberField(block, "speed", 1, 100000)
            && validateBooleanField(block, "move")
            && validateBooleanField(block, "next_button");
    };
    return DialogStageInterpreter;
}());
export { DialogStage, DialogStageInterpreter };
