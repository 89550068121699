import { validateStringField } from "../../../utils/validation";
var AuthorStage = /** @class */ (function () {
    function AuthorStage(value) {
        this.name = "Author";
        this.value = value;
    }
    AuthorStage.prototype.toJson = function () {
        return this.value;
    };
    return AuthorStage;
}());
var AuthorStageInterpreter = /** @class */ (function () {
    function AuthorStageInterpreter() {
        this.blockType = "author_stage";
    }
    AuthorStageInterpreter.prototype.interpret = function (block) {
        return new AuthorStage(block.getFieldValue("author"));
    };
    AuthorStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "author", "Please enter an author name.");
    };
    return AuthorStageInterpreter;
}());
export { AuthorStage, AuthorStageInterpreter };
// Path: src/types/stages/author.ts
