import { Region } from "../../../types/minecraft";
import { validateRegion } from "../../../utils/validation";
var PvpRegionStage = /** @class */ (function () {
    function PvpRegionStage(region) {
        this.name = "PvpRegion";
        this.region = region;
    }
    PvpRegionStage.prototype.toJson = function () {
        return this.region.toJson();
    };
    return PvpRegionStage;
}());
var PvpRegionStageInterpreter = /** @class */ (function () {
    function PvpRegionStageInterpreter() {
        this.blockType = "pvp_region_stage";
    }
    PvpRegionStageInterpreter.prototype.interpret = function (block) {
        return new PvpRegionStage(Region.fromBlock(block));
    };
    PvpRegionStageInterpreter.prototype.validate = function (block) {
        return validateRegion(block);
    };
    return PvpRegionStageInterpreter;
}());
export { PvpRegionStageInterpreter };
