import React, {FC} from "react";
import {WithChildren} from "../../utils/utils";
import {useScriptContext} from "../script/ScriptContextProvider";
import {useSessionContext} from "../sessions/SessionContextProvider";


const LoadingScreenComponent: FC<WithChildren> = ({children}) => {
    const {isScriptLoading, areScriptsLoading, scripts} = useScriptContext();
    const {isLoading: isSessionLoading, isIpLoading} = useSessionContext();

    if ((!isScriptLoading || (!areScriptsLoading && (scripts == null || scripts?.length === 0)))
        && !isSessionLoading
        && !isIpLoading
        && !areScriptsLoading)
        return <>{children}</>;

    return (
        <>
            <div
                style={{zIndex: 299}}
                className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center md:backdrop-blur-lg">
                <img
                    src="/assets/media/logo.webp"
                    alt="Logo"
                    className="mb-4"
                    style={{width: '100px', height: "auto"}}
                />
                <div
                    className="loader ease-linear rounded-full border-4 border-t-4 border-black-200 h-12 w-12 mb-4"></div>
                <h2 className="text-center text-black text-xl font-semibold">Loading...</h2>
                <p className="w-1/3 text-center text-black">This may take a few seconds, please don't close this
                    page.
                </p>
            </div>
            {children}
        </>
    );
}


export default LoadingScreenComponent;