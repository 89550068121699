type StageDocumentation = {
    id: string
    title: string
    description: string
    category: string
    image: string
}

type DocumentationContextProps = {
    shownStage: StageDocumentation | null
    setShownStage: (stage: StageDocumentation | null) => void
    isStageShown: boolean
}

const initialDocumentationContext: DocumentationContextProps = {
    shownStage: null,
    setShownStage: () => null,
    isStageShown: false
}

export {initialDocumentationContext};
export type {DocumentationContextProps, StageDocumentation}