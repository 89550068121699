var StartTimerStage = /** @class */ (function () {
    function StartTimerStage() {
        this.name = "StartTimer";
    }
    StartTimerStage.prototype.toJson = function () {
        return [];
    };
    return StartTimerStage;
}());
var StartTimerStageInterpreter = /** @class */ (function () {
    function StartTimerStageInterpreter() {
        this.blockType = "start_timer_stage";
    }
    StartTimerStageInterpreter.prototype.interpret = function () {
        return new StartTimerStage();
    };
    return StartTimerStageInterpreter;
}());
export { StartTimerStage, StartTimerStageInterpreter };
