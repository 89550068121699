export var MessageType;
(function (MessageType) {
    MessageType["Completion"] = "completion";
    MessageType["Process"] = "process";
    MessageType["Critical"] = "critical";
    MessageType["Error"] = "error";
    MessageType["Info"] = "info";
    MessageType["Plain"] = "plain";
})(MessageType || (MessageType = {}));
export function statusMessage(type) {
    var messages = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        messages[_i - 1] = arguments[_i];
    }
    switch (type) {
        case MessageType.Completion:
            messages.forEach(function (message) { return console.log("[+] ".concat(message)); });
            break;
        case MessageType.Process:
            messages.forEach(function (message) { return console.log("[\u2022] ".concat(message)); });
            break;
        case MessageType.Critical:
            messages.forEach(function (message) { return console.log("[X] ".concat(message)); });
            break;
        case MessageType.Error:
            messages.forEach(function (message) { return console.log("[ERROR] ".concat(message)); });
            break;
        case MessageType.Info:
            messages.forEach(function (message) { return console.log("[i] ".concat(message)); });
            break;
        case MessageType.Plain:
            messages.forEach(function (message) { return console.log(message); });
            break;
        default:
            messages.forEach(function (message) { return console.log(message); });
    }
}
