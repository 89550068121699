import { Location } from "../../../types/minecraft";
import { validateBooleanField, validateLocation, validateNumberField, validateStringField } from "../../../utils/validation";
var CreateMinecraftEntityStage = /** @class */ (function () {
    function CreateMinecraftEntityStage(key, displayName, entity, baby, look, sleeping, glow, speed, visible, location) {
        this.name = "CreateEntity";
        this.key = key;
        this.displayName = displayName;
        this.entity = entity;
        this.baby = baby;
        this.look = look;
        this.sleeping = sleeping;
        this.glow = glow;
        this.speed = speed;
        this.visible = visible;
        this.location = location;
    }
    CreateMinecraftEntityStage.prototype.toJson = function () {
        return {
            key: this.key,
            name: this.displayName,
            entity: this.entity,
            baby: this.baby,
            look: this.look,
            sleeping: this.sleeping,
            glow: this.glow,
            speed: this.speed,
            visible: this.visible,
            "location": [
                this.location.x,
                this.location.y,
                this.location.z,
                this.location.yaw,
                this.location.pitch
            ]
        };
    };
    return CreateMinecraftEntityStage;
}());
var CreateMinecraftEntityStageInterpreter = /** @class */ (function () {
    function CreateMinecraftEntityStageInterpreter() {
        this.blockType = "create_minecraft_entity_stage";
    }
    CreateMinecraftEntityStageInterpreter.prototype.interpret = function (block) {
        var key = block.getFieldValue("key");
        var displayName = block.getFieldValue("name");
        var entity = block.getFieldValue("entity");
        var baby = block.getFieldValue("baby");
        var look = block.getFieldValue("look");
        var sleeping = block.getFieldValue("sleeping");
        var glow = block.getFieldValue("glow");
        var speed = parseFloat(block.getFieldValue("speed"));
        var visible = block.getFieldValue("visible");
        var location = Location.fromBlock(block);
        return new CreateMinecraftEntityStage(key, displayName, entity, baby, look, sleeping, glow, speed, visible, location);
    };
    CreateMinecraftEntityStageInterpreter.prototype.validate = function (block) {
        return validateStringField(block, "key")
            && validateStringField(block, "name")
            && validateStringField(block, "entity")
            && validateBooleanField(block, "baby")
            && validateBooleanField(block, "look")
            && validateBooleanField(block, "sleeping")
            && validateBooleanField(block, "glow")
            && validateBooleanField(block, "visible")
            && validateNumberField(block, "speed", 0, 10)
            && validateLocation(block);
    };
    return CreateMinecraftEntityStageInterpreter;
}());
export { CreateMinecraftEntityStage, CreateMinecraftEntityStageInterpreter };
