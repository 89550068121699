import {createContext, FC, useContext, useState} from "react";
import {WithChildren} from "../../utils/utils";
import {initialThemeContext, ThemeContextProps} from "./ThemeModel";

const ThemeContext = createContext<ThemeContextProps>(initialThemeContext);

const ThemeContextProvider: FC<WithChildren> = ({children}) => {
    const [fullScreen, setFullScreen] = useState(false)
    const [darkMode, setDarkMode] = useState(false)

    return (
        <ThemeContext.Provider value={{
            isDarkMode: darkMode,
            toggleDarkMode: () => setDarkMode(!darkMode),
            isFullScreen: fullScreen,
            toggleFullScreen: () => fullScreen ? setFullScreen(false) : setFullScreen(true),
        }}>
            {children}
        </ThemeContext.Provider>
    )
}

const useThemeContext = () => useContext(ThemeContext);

export {ThemeContextProvider, useThemeContext};