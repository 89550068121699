import { Region } from "../../../types/minecraft";
import { validateRegion } from "../../../utils/validation";
var RegionExitStage = /** @class */ (function () {
    function RegionExitStage(region) {
        this.name = "RegionExit";
        this.region = region;
    }
    RegionExitStage.prototype.toJson = function () {
        return this.region.toJson();
    };
    return RegionExitStage;
}());
var RegionExitStageInterpreter = /** @class */ (function () {
    function RegionExitStageInterpreter() {
        this.blockType = "region_exit_stage";
    }
    RegionExitStageInterpreter.prototype.interpret = function (block) {
        return new RegionExitStage(Region.fromBlock(block));
    };
    RegionExitStageInterpreter.prototype.validate = function (block) {
        return validateRegion(block);
    };
    return RegionExitStageInterpreter;
}());
export { RegionExitStageInterpreter, RegionExitStage };
