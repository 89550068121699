import { Region } from "../../../types/minecraft";
import { validateRegion } from "../../../utils/validation";
var NoPokemonSummonRegionStage = /** @class */ (function () {
    function NoPokemonSummonRegionStage(region) {
        this.name = "NoPokemonSummonRegion";
        this.region = region;
    }
    NoPokemonSummonRegionStage.prototype.toJson = function () {
        return this.region.toJson();
    };
    return NoPokemonSummonRegionStage;
}());
var NoPokemonSummonRegionStageInterpreter = /** @class */ (function () {
    function NoPokemonSummonRegionStageInterpreter() {
        this.blockType = "no_pokemon_summon_region_stage";
    }
    NoPokemonSummonRegionStageInterpreter.prototype.interpret = function (block) {
        return new NoPokemonSummonRegionStage(Region.fromBlock(block));
    };
    NoPokemonSummonRegionStageInterpreter.prototype.validate = function (block) {
        return validateRegion(block);
    };
    return NoPokemonSummonRegionStageInterpreter;
}());
export { NoPokemonSummonRegionStage, NoPokemonSummonRegionStageInterpreter };
