import React from 'react';
import ReactDOM from 'react-dom/client';
import {App} from "./App";
import {initAxios} from "./app/sessions/_requests";
import {QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
import {ThemeContextProvider} from "./app/theme/ThemeContextProvider";

// Axios setup
initAxios();

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
    ReactDOM.createRoot(container).render(
        <QueryClientProvider client={queryClient}>
            <ThemeContextProvider>
                <App/>
            </ThemeContextProvider>
            <ReactQueryDevtools initialIsOpen={false}/>
        </QueryClientProvider>
    )
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
