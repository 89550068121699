import {createContext, FC, useContext, useEffect, useState} from "react";
import {initialSessionContext, Session, SessionContextProps} from "./SessionModel";
import {useQuery} from "react-query";
import {QUERIES} from "../../utils/consts";
import axios from "axios";
import {findSessionByIP} from "./_requests";
import {WithChildren} from "../../utils/utils";
import {NoSession} from "../components/errors/NoSession";
import {toast} from "react-toastify";

const SessionContext = createContext<SessionContextProps>(initialSessionContext);

const SessionContextProvider: FC<WithChildren> = ({children}) => {
    const [ip, setIP] = useState("")
    const [session, setSession] = useState<Session | null>();

    const {
        isLoading,
        refetch: refreshSession,
    } = useQuery(QUERIES.GET_SESSION, () => findSessionByIP(ip), {
        cacheTime: 600,
        enabled: ip !== "",
        onError: (err: any) => {
            toast.error(`Failed to get session: ${err}`)
            setSession(null)
        },
        onSuccess: (data) => {
            if (data && data.id !== undefined)
                onSessionUpdated(data)
        }
    });

    const fetchIP = () => {
        axios.get("https://api.ipify.org/?format=json", {
            transformRequest: (data, headers) => {
                headers.delete('x-api-key');
                return data;
            }
        })
            .then((data: any) => data.data.ip)
            .then((ip: string) => setIP(ip))
    };

    const onSessionUpdated = (session: Session) => {
        if (session.ended_at && new Date(session.ended_at) < new Date()) {
            onSessionExpired()
            return
        }
        setSession(session);
    }
    const onSessionExpired = () => setSession(null);

    useEffect(() => fetchIP(), []);

    if ((ip && !isLoading) && (session === undefined || session === null))
        return <NoSession/>

    return (
        <SessionContext.Provider value={{
            isLoading,
            refreshSession,
            session,
            isIpLoading: !ip || ip === "",
            hasSession: () => ip !== null
                && ip !== ""
                && !isLoading
                && session !== undefined
                && session !== null
                && (session.ended_at == null
                    || new Date(session.ended_at) > new Date()),
            onSessionUpdated,
            onSessionExpired,
            setHasCompletedInitialTour: () => localStorage.setItem("has_completed_initial_tour", "true"),
            hasCompletedInitialTour: localStorage.getItem("has_completed_initial_tour") === "true"
        }}>
            {children}
        </SessionContext.Provider>
    );
}

const useSessionContext = () => useContext(SessionContext);

export {SessionContextProvider, useSessionContext};