import { Vector } from "../../../types/minecraft";
import { TextInterpreter } from "../../blockly/textInterpreter";
import { validateBlockInputPresence, validateVector } from "../../../utils/validation";
var HologramStage = /** @class */ (function () {
    function HologramStage(location, lines) {
        this.name = "Hologram";
        this.location = location;
        this.lines = lines;
    }
    HologramStage.prototype.toJson = function () {
        var json = [
            this.location.x,
            this.location.y,
            this.location.z
        ];
        this.lines.forEach(function (value) { return json.push(value); });
        return json;
    };
    return HologramStage;
}());
var HologramStageInterpreter = /** @class */ (function () {
    function HologramStageInterpreter() {
        this.blockType = "hologram_stage";
    }
    HologramStageInterpreter.prototype.interpret = function (block) {
        var inputBlock = block.getInputTargetBlock('NAME');
        return new HologramStage(Vector.fromBlock(block), ((inputBlock === null || inputBlock === void 0 ? void 0 : inputBlock.type) === 'text' ? [TextInterpreter.interpret(inputBlock)] : TextInterpreter.interpretList(inputBlock)));
    };
    HologramStageInterpreter.prototype.validate = function (block) {
        var inputBlock = block.getInputTargetBlock('NAME');
        return validateVector(block)
            && validateBlockInputPresence(block, "NAME")
            && (inputBlock === null || inputBlock === void 0 ? void 0 : inputBlock.type) === 'text' ? TextInterpreter.validate(block, inputBlock) : TextInterpreter.validateList(block, inputBlock);
    };
    return HologramStageInterpreter;
}());
export { HologramStageInterpreter, HologramStage };
