import { Location } from "../../../types/minecraft";
import { validateLocation, validateNumberField } from "../../../utils/validation";
var ControlledTrackStage = /** @class */ (function () {
    function ControlledTrackStage(location, speed) {
        this.name = "ControlledTrack";
        this.location = location;
        this.speed = speed;
    }
    ControlledTrackStage.prototype.toJson = function () {
        if (this.speed == 0.3 && this.location.yaw == 0)
            return [
                this.location.x,
                this.location.y,
                this.location.z
            ];
        if (this.speed == 0.3)
            return [
                this.location.x,
                this.location.y,
                this.location.z,
                this.location.yaw
            ];
        return [
            this.location.x,
            this.location.y,
            this.location.z,
            this.location.yaw,
            this.speed
        ];
    };
    return ControlledTrackStage;
}());
var ControlledTrackStageInterpreter = /** @class */ (function () {
    function ControlledTrackStageInterpreter() {
        this.blockType = "controlled_track_stage";
    }
    ControlledTrackStageInterpreter.prototype.interpret = function (block) {
        return new ControlledTrackStage(Location.fromBlock(block), Number(block.getFieldValue("speed")));
    };
    ControlledTrackStageInterpreter.prototype.validate = function (block) {
        return validateLocation(block)
            && validateNumberField(block, "speed", 0.01, 1.0);
    };
    return ControlledTrackStageInterpreter;
}());
export { ControlledTrackStage, ControlledTrackStageInterpreter };
