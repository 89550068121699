var IfStage = /** @class */ (function () {
    function IfStage() {
        this.name = "If";
    }
    IfStage.prototype.toJson = function () {
        return [];
    };
    return IfStage;
}());
var IfStageInterpreter = /** @class */ (function () {
    function IfStageInterpreter() {
        this.blockType = "controls_if";
    }
    IfStageInterpreter.prototype.interpret = function (block) {
        var _a;
        block.setCommentText("if_single");
        var condition = block.getInputTargetBlock("IF0");
        var ifBodyHead = block.getInputTargetBlock("DO0");
        var elseBlock = block.getInputTargetBlock("ELSE");
        // Sanity check
        if (!condition || !ifBodyHead)
            throw new Error("If block has no condition");
        var multipleIfBody = (_a = block.getInputTargetBlock("DO0")) === null || _a === void 0 ? void 0 : _a.getNextBlock();
        var multipleElseBody = elseBlock === null || elseBlock === void 0 ? void 0 : elseBlock.getNextBlock();
        condition.setCommentText("if_condition");
        ifBodyHead.setCommentText("if_body");
        if (!multipleElseBody && elseBlock)
            elseBlock.setCommentText("else_body");
        if (!multipleElseBody && !multipleIfBody)
            return new IfStage();
        block.setCommentText("if_multiple");
        // If block
        var nextBlock = ifBodyHead;
        while (nextBlock) {
            nextBlock.setCommentText("if_body");
            nextBlock = nextBlock.getNextBlock();
        }
        // Else block
        if (elseBlock) {
            nextBlock = elseBlock;
            while (nextBlock) {
                nextBlock.setCommentText("else_body");
                nextBlock = nextBlock.getNextBlock();
            }
        }
        return new IfStage();
    };
    IfStageInterpreter.prototype.validate = function (block) {
        var condition = block.getInputTargetBlock("IF0");
        var ifBodyHead = block.getInputTargetBlock("DO0");
        var elseBlock = block.getInputTargetBlock("ELSE");
        if (!condition) {
            block.setWarningText("This block has no condition");
            return false;
        }
        else if (!ifBodyHead) {
            block.setWarningText("This block has no content");
            return false;
        }
        block.setWarningText(null);
        return true;
    };
    return IfStageInterpreter;
}());
export { IfStage, IfStageInterpreter };
