const FooterComponent = () => {
    return (
        <footer className="p-4 bg-white md:p-8 lg:p-10 dark:bg-gray-800">
            <div className="mx-auto max-w-screen-xl text-center">
                <a href="https://pokefind.co"
                   className="flex justify-center items-center text-2xl font-semibold text-gray-900 dark:text-white">
                    <img src="/assets/media/logo.webp" className="mr-3 h-6 sm:h-9"
                         alt="PokeFind Logo"/>
                    PokeFind
                </a>
                <p className="my-6 text-gray-500 dark:text-gray-400">PokeFind’s Script creator for Housing. Server IP:
                    play.pokefind.co Port: 19132</p>
                <ul className="flex flex-wrap justify-center items-center mb-6 text-gray-900 dark:text-white">
                    <li>
                        <a href="#" className="mr-4 hover:underline md:mr-6">Documentation</a>
                    </li>
                    <li>
                        <a href="https://discord.com/invite/pokefind" target="_blank"
                           className="mr-4 hover:underline md:mr-6 ">Discord</a>
                    </li>
                    <li>
                        <a href="https://store.pokefind.co/" target="_blank" className="mr-4 hover:underline md:mr-6">Store</a>
                    </li>
                    <li>
                        <a href="https://www.pokefind.co/" target="_blank" className="mr-4 hover:underline md:mr-6 ">Website</a>
                    </li>
                </ul>
                <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a
                    href="https://pokefind.co"
                    className="hover:underline">PokeFind™</a>. All Rights Reserved.</span>
            </div>
        </footer>
    )
}

export {FooterComponent}