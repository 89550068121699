import {ScriptTemplate} from "../../templates/template";
import React from "react";

interface TemplateProps {
    template: ScriptTemplate
    selected?: boolean
    onClick: (template: ScriptTemplate) => void
}

const TemplateComponent: React.FC<TemplateProps> = ({template, onClick, selected}) => {
    const enabled = template.path !== undefined && template.path !== null && template.path !== ""
    return (
        <li>
            <div
                onClick={() => enabled && onClick(template)}
                className={`${!enabled && 'blur-[2px] cursor-not-allowed'} inline-flex items-center justify-between w-full p-5 text-gray-900 bg-white border border-${selected ? 'green' : 'gray'}-200 rounded-lg cursor-pointer dark:border-${selected ? 'green' : 'gray'}-500 dark:text-white dark:bg-gray-600 ${enabled && 'hover:text-gray-900 dark:hover:text-gray-300 dark:hover:bg-gray-500 hover:bg-gray-100'}`}>
                <div className="block">
                    <div className="w-full text-lg font-semibold">{template.name}</div>
                    <div className="w-full text-gray-500 dark:text-gray-400">
                        {template.description}
                    </div>
                </div>
                {selected ? (
                    <svg className="w-3.5 h-3.5 text-green-500 dark:text-green-400"
                         aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"/>
                    </svg>
                ): (
                    <svg className="w-4 h-4 ms-3 rtl:rotate-180 text-gray-500 dark:text-gray-400"
                         aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 14 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                              strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                )}
            </div>
        </li>
    )
}

export default TemplateComponent;