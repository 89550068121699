import { validateBooleanField } from "../../../utils/validation";
var AllowRejoiningStage = /** @class */ (function () {
    function AllowRejoiningStage(value) {
        this.name = "AllowRejoining";
        this.value = value;
    }
    AllowRejoiningStage.prototype.toJson = function () {
        return this.value;
    };
    return AllowRejoiningStage;
}());
var AllowRejoiningStageInterpreter = /** @class */ (function () {
    function AllowRejoiningStageInterpreter() {
        this.blockType = "allow_rejoining_stage";
    }
    AllowRejoiningStageInterpreter.prototype.interpret = function (block) {
        return new AllowRejoiningStage(block.getFieldValue("value"));
    };
    AllowRejoiningStageInterpreter.prototype.validate = function (block) {
        return validateBooleanField(block, "value");
    };
    return AllowRejoiningStageInterpreter;
}());
export { AllowRejoiningStage, AllowRejoiningStageInterpreter };
