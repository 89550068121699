import { validateNumberField } from "../../../utils/validation";
var PlayTimeStage = /** @class */ (function () {
    function PlayTimeStage(days, hours) {
        this.name = "Playtime";
        this.days = days;
        this.hours = hours;
    }
    PlayTimeStage.prototype.toJson = function () {
        return [this.days, this.hours];
    };
    return PlayTimeStage;
}());
var PlayTimeStageInterpreter = /** @class */ (function () {
    function PlayTimeStageInterpreter() {
        this.blockType = "play_time_stage";
    }
    PlayTimeStageInterpreter.prototype.interpret = function (block) {
        var hours = block.getFieldValue("playtime_hours");
        var days = block.getFieldValue("playtime_days");
        var months = block.getFieldValue("playtime_months");
        if (months > 0)
            days += months * 30;
        return new PlayTimeStage(days, hours);
    };
    PlayTimeStageInterpreter.prototype.validate = function (block) {
        return validateNumberField(block, "playtime_hours", 0, 24)
            && validateNumberField(block, "playtime_days", 0, 31)
            && validateNumberField(block, "playtime_months", 0, 12);
    };
    return PlayTimeStageInterpreter;
}());
export { PlayTimeStage, PlayTimeStageInterpreter };
