var DummyStage = /** @class */ (function () {
    function DummyStage() {
        this.name = "Dummy";
    }
    DummyStage.prototype.toJson = function () {
        return [];
    };
    return DummyStage;
}());
var DummyStageInterpreter = /** @class */ (function () {
    function DummyStageInterpreter() {
        this.blockType = "dummy_stage";
    }
    DummyStageInterpreter.prototype.interpret = function () {
        return new DummyStage();
    };
    return DummyStageInterpreter;
}());
export { DummyStageInterpreter, DummyStage };
