var Script = /** @class */ (function () {
    function Script(name, stages) {
        this.stages = [];
        this.name = name;
        this.stages = stages;
    }
    Script.prototype.toJson = function () {
        var json = "{";
        for (var _i = 0, _a = this.stages; _i < _a.length; _i++) {
            var stage = _a[_i];
            json += "\"".concat(stage.name(), "\":");
            if (stage.name() === "NewDialog") {
                json += stage.toJson();
            }
            else {
                json += JSON.stringify(stage.toJson());
            }
            json += ",";
        }
        json = json.slice(0, -1);
        json += "}";
        return json;
    };
    return Script;
}());
export default Script;
