import { DialogMessage, Pokemon } from "../../../types/pokefind";
import { validateBlockInputPresence, validateBooleanField, validateEntityKey, validateStringField } from "../../../utils/validation";
import { TextInterpreter } from "../../blockly/textInterpreter";
import { ListInterpreter } from "../../blockly/listInterpreter";
var BattleNPCStage = /** @class */ (function () {
    function BattleNPCStage(entityKey, introductionDialog, winDialog, loseDialog, pokemons, automatic, hardcore, progressLoss, conditional) {
        this.name = "BattleNpc";
        this.entityKey = entityKey;
        this.introductionDialog = introductionDialog;
        this.winDialog = winDialog;
        this.loseDialog = loseDialog;
        this.pokemons = pokemons;
        this.automatic = automatic;
        this.hardcore = hardcore;
        this.progressLoss = progressLoss;
        this.conditional = conditional;
    }
    BattleNPCStage.prototype.toJson = function () {
        return [
            this.entityKey,
            this.serializeDialogMessages(this.introductionDialog),
            this.serializeDialogMessages(this.winDialog),
            this.serializeDialogMessages(this.loseDialog),
            this.pokemons.map(function (pokemon) { return pokemon.toJson(); }),
            {
                exp_multiplier: 0,
                automatically_start: this.automatic,
                hardcore: this.hardcore,
                progress_loss: this.progressLoss,
                conditional: this.conditional
            }
        ];
    };
    BattleNPCStage.prototype.serializeDialogMessages = function (dialogMessages) {
        var messages = {};
        for (var _i = 0, dialogMessages_1 = dialogMessages; _i < dialogMessages_1.length; _i++) {
            var message = dialogMessages_1[_i];
            messages[message.sender] = message.message;
        }
        return messages;
    };
    return BattleNPCStage;
}());
var BattleNPCStageInterpreter = /** @class */ (function () {
    function BattleNPCStageInterpreter() {
        this.blockType = "battle_npc_stage";
    }
    BattleNPCStageInterpreter.prototype.interpret = function (block) {
        var entityKey = block.getFieldValue("entity_key");
        var automatic = block.getFieldValue("automatically_start");
        var hardcore = block.getFieldValue("hardcore");
        var progressLoss = block.getFieldValue("progress_loss");
        var conditional = block.getFieldValue("conditional");
        // Pokemons
        var pokemonsBlock = block.getInputTargetBlock("pokemons");
        var pokemons = pokemonsBlock.type === 'lists_create_with' ? pokemonsBlock.inputList
            .filter(function (value) { return value.connection != null; })
            .map(function (input) { var _a; return (_a = input.connection) === null || _a === void 0 ? void 0 : _a.targetBlock(); })
            .map(function (pokemonBlock) { return Pokemon.fromBlock(pokemonBlock); }) : [Pokemon.fromBlock(pokemonsBlock)];
        var introductionDialog = this.parseDialogMessages(block, "intro_dialog");
        var winDialog = this.parseDialogMessages(block, "win_dialog");
        var loseDialog = this.parseDialogMessages(block, "lose_dialog");
        return new BattleNPCStage(entityKey, introductionDialog, winDialog, loseDialog, pokemons, automatic, hardcore, progressLoss, conditional);
    };
    BattleNPCStageInterpreter.prototype.validate = function (block) {
        if (!(validateStringField(block, "entity_key")
            && validateEntityKey(block, block.getFieldValue("entity_key"))
            && this.validateDialogMessages(block, "intro_dialog")
            && this.validateDialogMessages(block, "win_dialog")
            && this.validateDialogMessages(block, "lose_dialog")
            && validateBlockInputPresence(block, "pokemons")
            && validateBooleanField(block, "automatically_start")
            && validateBooleanField(block, "hardcore")
            && validateBooleanField(block, "progress_loss")
            && validateBooleanField(block, "conditional")))
            return false;
        var pokemonsBlock = block.getInputTargetBlock("pokemons");
        if (ListInterpreter.isList(pokemonsBlock)) {
            return ListInterpreter.interpretList(pokemonsBlock, "pokemon_object")
                .every(function (pokemonBlock) { return Pokemon.validate(pokemonBlock); });
        }
        else {
            return Pokemon.validate(pokemonsBlock);
        }
    };
    BattleNPCStageInterpreter.prototype.parseDialogMessages = function (block, inputName) {
        var dialogMessagesBlock = block.getInputTargetBlock(inputName);
        return dialogMessagesBlock.type === 'lists_create_with' ? dialogMessagesBlock.inputList
            .filter(function (value) { return value.connection != null; })
            .map(function (input) { var _a; return (_a = input.connection) === null || _a === void 0 ? void 0 : _a.targetBlock(); })
            .filter(function (dialogMessageBlock) { return (dialogMessageBlock === null || dialogMessageBlock === void 0 ? void 0 : dialogMessageBlock.type) === 'dialog_message_object'; })
            .map(function (dialogMessageBlock) { return dialogMessageBlock; })
            .map(function (dialogMessageBlock) {
            var sender = dialogMessageBlock.getFieldValue("sender");
            var message = TextInterpreter.interpret(dialogMessageBlock.getInputTargetBlock("message"));
            if (!message)
                return null;
            return new DialogMessage(sender, message);
        })
            .filter(function (value) { return value != null; })
            : [new DialogMessage(dialogMessagesBlock.getFieldValue("sender"), TextInterpreter.interpret(dialogMessagesBlock.getInputTargetBlock("message")))];
    };
    BattleNPCStageInterpreter.prototype.validateDialogMessages = function (block, inputName) {
        var dialogMessagesBlock = block.getInputTargetBlock(inputName);
        if (!dialogMessagesBlock) {
            block.setWarningText("Dialog stage must have at least one message");
            return false;
        }
        var nextBlock = dialogMessagesBlock;
        while (nextBlock && nextBlock.type === "dialog_message_object") {
            if (!validateStringField(nextBlock, "sender", "Dialog stage must have a sender"))
                return false;
            else
                nextBlock.setWarningText(null);
            if (!TextInterpreter.validate(nextBlock, nextBlock.getInputTargetBlock("message")))
                return false;
            else
                nextBlock.setWarningText(null);
            nextBlock = nextBlock.getNextBlock();
        }
        block.setWarningText(null);
        return true;
    };
    return BattleNPCStageInterpreter;
}());
export { BattleNPCStageInterpreter, BattleNPCStage };
