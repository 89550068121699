import { WrappedStage } from "../types/stage";
function findFirstByComment(stages, comment, startIndex) {
    return stages.filter(function (stage, index) { return index >= startIndex; })
        .find(function (stage) { return stage.getComment() === comment; });
}
function findByComment(stages, comment, startIndex) {
    return stages.filter(function (stage, index) { return index >= startIndex; })
        .filter(function (stage) { return stage.getComment() === comment; });
}
function findByName(stages, name) {
    return stages.find(function (stage) { return stage.name() === name; });
}
function updateNeighbours(stage) {
    var previous = stage.previousStage();
    var next = stage.nextStage();
    if (previous && next) {
        previous.setNext(next);
        next.setPrevious(previous);
    }
    else if (previous) {
        previous.setNext(null);
    }
    else if (next) {
        next.setPrevious(null);
    }
}
function pop(stages, stage) {
    var index = stages.indexOf(stage);
    if (index === -1)
        return;
    stages.splice(index, 1);
    updateNeighbours(stage);
}
function push(stages, stage) {
    var lastStage = stages[stages.length - 1];
    stages.push(stage);
    stage.setPrevious(lastStage);
    lastStage.setNext(stage);
}
function insertAfter(stages, stage, after) {
    var index = stages.indexOf(after);
    if (index === -1)
        return stage;
    stages.splice(index + 1, 0, stage);
    var previous = after.previousStage();
    if (previous) {
        previous.setNext(after);
        after.setPrevious(previous);
    }
    after.setPrevious(stage);
    stage.setNext(after);
    return stages[index + 1];
}
function insertBefore(stages, stage, before) {
    var index = stages.indexOf(before);
    if (index === -1)
        return stage;
    stages.splice(index, 0, stage);
    var next = before.nextStage();
    if (next) {
        next.setPrevious(before);
        before.setNext(next);
    }
    before.setNext(stage);
    stage.setPrevious(before);
    return stages[index - 1];
}
function insert(stages, stage, index) {
    stages.splice(index, 0, stage);
    var previous = stages[index - 1];
    var next = stages[index + 1];
    if (previous) {
        previous.setNext(stage);
        stage.setPrevious(previous);
    }
    if (next) {
        next.setPrevious(stage);
        stage.setNext(next);
    }
}
function createEmptyWrappedStage(stage) {
    return new WrappedStage(stage, null, null, null);
}
function moveAfter(stages, toMove, after) {
    for (var _i = 0, toMove_1 = toMove; _i < toMove_1.length; _i++) {
        var stage = toMove_1[_i];
        pop(stages, stage);
    }
    for (var _a = 0, toMove_2 = toMove; _a < toMove_2.length; _a++) {
        var stage = toMove_2[_a];
        insertAfter(stages, stage, after);
    }
    var lastIndex = stages.indexOf(toMove[toMove.length - 1]);
    return stages[lastIndex + 1];
}
function replace(stages, toReplace, replaceWith) {
    var index = stages.indexOf(toReplace);
    if (index === -1)
        return replaceWith;
    stages[index] = replaceWith;
    updateNeighbours(toReplace);
    return stages[index];
}
export { findFirstByComment, findByComment, findByName, push, pop, insertAfter, insertBefore, insert, createEmptyWrappedStage, moveAfter, replace };
